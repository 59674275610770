import "./Roles.css";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import AddIcon from "@mui/icons-material/Add";
import { Form, Row, Col } from "react-bootstrap";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ButtonCom from "../../../components/button/button";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  saveRole,
  initState,
} from "../../../redux/slices/roleSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";

const Roles = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];
  const dispatch = useDispatch();
  const state = useSelector((state) => state.roles);
  const commonState = useSelector((state) => state.common);

  const [open, setOpen] = useState(false);

  const [validated, setValidated] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [roleList, setRoleList] = useState([]);
  const [roles, setRoles] = useState({
    userId: user?.user_id,
    roleId: null,
    roleName: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRoles("");
    setValidated(false);
    setOpen(false);
  };

  const getRoleList = useCallback(() => {
    dispatch(fetchData());
  }, []);

  useEffect(() => {
    getRoleList();
  }, []);

  useEffect(() => {
    if (state.roleGetSuccess) {
      setRoleList(state.roleGetData);
    }
    if (state.roleGetError) {
      console.log(state.roleGetMessage);
    }
  }, [state.roleGetSuccess, state.roleGetError]);

  const handelChange = (e) => {
    const { value } = e.target;
    setRoles({
      ...roles,
      roleName: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(saveRole(roles));
    }
  };

  useEffect(() => {
    if (state.roleSuccess) {
      toast.success(state.roleMessage)
      handleClose();
      setRoles({
        ...roles,
        roleName: "",
      });
      dispatch(initState());
      getRoleList();
    }
    if (state.roleError) {
      toast.error(state.roleMessage)
      handleClose();
      setRoles({
        ...roles,
        roleName: "",
      });
      dispatch(initState());
    }
  }, [state.roleSuccess, state.roleError]);

  // Edit functions

  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = (row) => {
    setIsEdit(true);
    setRoles({
      ...roles,
      roleId: row.roleId,
      roleName: row.rolename,
    });
    handleClickOpen();
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateRoles",
      data: {
        userId: user?.user_id,
        roleId: roles.roleId,
        roleName : roles.roleName
      },
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.roleId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteRoles",
      data: {
        userId: user?.user_id,
        roleId: parseInt(delId),
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getRoleList();
      setIsEdit(false);
      setDelId(null);
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      getRoleList();
      setIsEdit(false);
      setDelId(null);
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const heading = [
    {
      name: "Role",
      label: "rolename",
    },
    {
      name: "Status",
      label: "status",
      render: (rowData) => {
        return rowData.status == 1 ? "Active" : "InActive";
      },
    },
    {
      name: "",
      label: "id",
      width: 300,
      render: (rowData) => {
        return (
          <div className="Department-table-button">
            <CiEdit
              style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
              onClick={() => {
                handleEdit(rowData);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(rowData)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Roles</p>
      </div>

      <div className="SettingBody-card mt-4">
        <div className="Branches-body">
          <div className="Branches-table">
            <div className="Departments-btn mb-3">
              <h1>Roles </h1>
              <ButtonCom
                type={"button"}
                class_name={"p_btn"}
                text={"Add Roles"}
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            <TableMui
              tableHead={heading}
              data={roleList}
              rowPagesPlay={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              Pages={10}
              editId={false}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              Add New Role
            </DialogTitle>
            <Form noValidate validated={validated} onSubmit={isEdit ? handleEditSubmit : handleSubmit}>
              <DialogContent>
                <Row>
                  <Col sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>Role Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="roleName"
                        value={roles.roleName}
                        placeholder="Enter Role Name"
                        onChange={handelChange}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Please provide valid role name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={handleClose}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>

          <div className="Branches-btn"></div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
