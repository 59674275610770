import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Button from "react-bootstrap/Button";
import ButtonCom from "../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { MdCreateNewFolder } from "react-icons/md";
import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
    commonGetData,
    commonGetDataFourth,
    commonSaveData,
    initCommonState,
} from "../../redux/slices/commonSlice";

import {
    getAttendModule,
    initMoreSettState,
} from "../../redux/slices/moreSettings";

const LeaveModule = () => {
    const navigate = useNavigate()
    const user = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : [];

    const dispatch = useDispatch();
    const store = useSelector((state) => state.moreSettings);
    const commonStore = useSelector((state) => state.common);
    const [isSave, setIsSave] = useState(false)

    const [moduleList, setModuleList] = useState([]);


    // Delete function starts here --------------------------------
    const [show, setShow] = useState(false);
    const handleModalOpen = () => setShow(true);
    const handleModalClose = () => setShow(false);
    const [deleteId, setDeleteId] = useState(null);
    const handleDelete = (id) => {
        setDeleteId(id);
        handleModalOpen();
    };
    const handleDeleteSubmit = () => {
        let data = {
            path: "admin/deleteLeaveMain",
            data: {
                userId: user.user_id,
                leaveId: deleteId,
            },
        };
        dispatch(commonSaveData(data));
        handleModalClose();
    };

    useEffect(() => {
        if (commonStore.commonSuccess) {
            toast.success(commonStore.commonMessage);
            getLeaveModuleList();
            handleModalClose1();
            if (isSave) {
                setIsSave(false)
                navigate(`/leave-attendance/${commonStore.commonData.id}`)
            }
        }
        if (commonStore.commonError) {
            toast.error(commonStore.commonMessage);
        }
    }, [commonStore.commonSuccess, commonStore.commonError]);


    const getLeaveModuleList = useCallback((id) => {
        const path = `admin/getLeaveMain?userId=${user.user_id}`;
        dispatch(commonGetDataFourth(path));
    }, []);
    useEffect(() => {
        getLeaveModuleList()
    }, [])

    useEffect(() => {
        if (commonStore.commonGetFourthSuccess) {
            setModuleList(commonStore.commonGetFourthData);
            dispatch(initCommonState());
        }
    }, [commonStore.commonGetFourthSuccess]);


    const [show1, setShow1] = useState(false);

    const handleModalClose1 = () => {
        setShow1(false);
    };
    const handleShow1 = () => setShow1(true);

    const [moduleName, setModuleName] = useState("")
    const leaveHandleChange = (e) => {
        setModuleName(e.target.value)
    }

    const createHolidayModule = (e) => {
        e.preventDefault();
        const data = {
            path: `admin/insertLeaveMain`,
            data: {
                "userId": user.user_id,
                "moduleName": moduleName
            }
        }
        dispatch(commonSaveData(data))
        setIsSave(true)
    };

    const heading = [
        {
            headerName: "Module Name",
            field: "moduleName",
            flex: 1,
        },

        {
            headerName: "Action",
            field: "id",
            width: 200,
            cellRenderer: (rowData) => {
                return (
                    <>
                        <div className="Branches-table-button">
                            {/* <MdCreateNewFolder
                                style={{
                                    cursor: "pointer",
                                    fontSize: 25,
                                    color: "#58d68d",
                                    marginRight: "10px",
                                }}
                                onClick={() => {
                                    handleShow1();
                                }}
                            /> */}
                            <Link to={`/leave-attendance/${rowData.data.moduleId}`}>
                                <CiEdit
                                    style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                                />
                            </Link>

                            <MdOutlineDeleteSweep
                                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                                onClick={() => handleDelete(rowData.data.moduleId)}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    //===============================
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [10, 20, 50, 100];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                className="toast-custom"
            />

            {/* Modal for create new holiday module */}
            <Modal show={show1} onHide={handleModalClose1} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Create new module</Modal.Title>
                </Modal.Header>
                <Form onSubmit={createHolidayModule}>
                    <Modal.Body style={{ display: "flex", flexDirection: "column" }}>

                        <Form.Group>
                            <Form.Control placeholder="Eneter leave module name" value={moduleName} name="moduleName" onChange={leaveHandleChange} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{
                                backgroundColor: "#566573",
                                color: "#fff",
                                marginRight: "5px",
                            }}
                            onClick={handleModalClose1}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            style={{ backgroundColor: "#5dade2", color: "#fff" }}
                            onClick={createHolidayModule}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={show} onHide={handleModalClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Module</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure! to delete this record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSubmit}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="SettingBody">
                <div className="SettingBody-head">
                    <Link to={"/setting/"}>
                        <ArrowBackIcon sx={{ scale: 1 }} />
                    </Link>
                    <p>Leave Module</p>
                </div>

                <div className="SettingBody-card mt-3">
                    <div className="Branches-body" style={{ borderRadius: "7px" }}>
                        <div className="Branches-btn">

                            <ButtonCom
                                type="button"
                                class_name="p_btn"
                                text={"Create Leave Module"}
                                url_or_onclick={handleShow1}
                                icon={<AddIcon />}
                                width={""}
                            />

                        </div>
                        <div
                            className="ag-theme-quartz" // applying the Data Grid theme
                            style={{ height: 350, width: "100%" }}
                        >
                            <AgGridReact
                                rowData={moduleList}
                                columnDefs={heading}
                                omLayout="autoHeight"
                                pagination={true}
                                paginationPageSize={paginationPageSize}
                                paginationPageSizeSelector={paginationPageSizeSelector}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(LeaveModule);
