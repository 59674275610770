// import React, { useEffect, useState } from "react";
// // icons
// import MarkerIcon from "../../assets/icons/location-position.png";

// import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

// const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// const Map = ({ zoom = 10, width = "100%", height = "100%" }) => {
//   const [isBtnView, setIsBtnView] = useState(false);

//   const containerStyle = {
//     width: width,
//     height: height,
//   };

//   const center = {
//     lat: 21.348480687703674,
//     lng: 81.70718910059041, // Initial center of the map
//   };

//   const preMarkers = [
//     { lat: 21.348480687703674, lng: 81.70718910059041, name: "Marker 1" },
//     { lat: 21.286980410083334, lng: 81.72022422964724, name: "Marker 2" },
//     { lat: 21.269739555952803, lng: 81.67135381306662, name: "Marker 3" },
//     { lat: 21.244142402184515, lng: 81.63496160183927, name: "Marker 4" },
//   ];

//   const markers = [
//     { lat: 21.348480687703674, lng: 81.70718910059041, name: "Marker 1" },
//     { lat: 21.286980410083334, lng: 81.72022422964724, name: "Marker 2" },
//     { lat: 21.269739555952803, lng: 81.67135381306662, name: "Marker 3" },
//     { lat: 21.244142402184515, lng: 81.63496160183927, name: "Marker 4" },
//   ];

//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: API_KEY,
//   });

//   const [map, setMap] = React.useState(null);

//   const [newMarkers, setNewMarkers] = useState([]);

//   const [markerAddresses, setMarkerAddresses] = useState("");
//   const getAddress = async (e) => {
//     let address = "";
//     try {
//       const response = await fetch(
//         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.lat},${e.lng}&key=${API_KEY}`
//       );
//       const data = await response.json();
//       if (data.status === "OK" && data.results[0]) {
//         setMarkerAddresses(data.results[0].formatted_address);
//         address = data.results[0].formatted_address;
//       }
//     } catch (error) {
//       console.error("Error fetching address:", error);
//     }
//     return address;
//   };

//   const onLoad = React.useCallback(function callback(map) {
//     setMap(map);
//     setIsBtnView(true);
//   }, []);

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null);
//     setIsBtnView(false);
//   }, []);

//   const mapOptions = {
//     streetViewControl: false,
//     mapTypeControl: false,
//   };

//   useEffect(() => {
//     console.log(newMarkers);
//   }, [newMarkers.length]);

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={center}
//       zoom={zoom}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//       options={mapOptions}
//     >
//       {markers.map((marker, index) => (
//         <Marker
//           key={index}
//           position={{ lat: marker.lat, lng: marker.lng }}
//           title={marker.name}
//           icon={{
//             url: MarkerIcon,
//             scaledSize: new window.google.maps.Size(24, 24), // Define size explicitly
//           }}
//           draggable
//           onClick={() => getAddress(marker)}
//         />
//       ))}
//     </GoogleMap>
//   ) : (
//     <></>
//   );
// };

// export default React.memo(Map);

// // import React, { useState, useEffect } from "react";
// // import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
// // import MarkerIcon from "../../assets/icons/location-position.png";

// // const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// // const Map = ({ zoom = 10, width = "100%", height = "100%" }) => {
// //   const [selectedMarker, setSelectedMarker] = useState(null);
// //   const [markerAddresses, setMarkerAddresses] = useState({}); // To store addresses

// //   const containerStyle = {
// //     width: width,
// //     height: height,
// //   };

// //   const center = {
// //     lat: 21.348480687703674,
// //     lng: 81.70718910059041, // Initial center of the map
// //   };

// //   const { isLoaded } = useJsApiLoader({
// //     id: "google-map-script",
// //     googleMapsApiKey: API_KEY,
// //   });

// //   const markers = [
// //     { id: 1, lat: 21.348480687703674, lng: 81.70718910059041, name: "Marker 1" },
// //     { id: 2, lat: 21.286980410083334, lng: 81.72022422964724, name: "Marker 2" },
// //     { id: 3, lat: 21.269739555952803, lng: 81.67135381306662, name: "Marker 3" },
// //     { id: 4, lat: 21.244142402184515, lng: 81.63496160183927, name: "Marker 4" },
// //   ];

// //   // Fetch address using Geocoding API
// //   const fetchAddress = async (lat, lng, id) => {
// //     try {
// //       const response = await fetch(
// //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
// //       );
// //       const data = await response.json();
// //       if (data.status === "OK" && data.results[0]) {
// //         setMarkerAddresses((prev) => ({
// //           ...prev,
// //           [id]: data.results[0].formatted_address,
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching address:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     markers.forEach((marker) => {
// //       if (!markerAddresses[marker.id]) {
// //         fetchAddress(marker.lat, marker.lng, marker.id);
// //       }
// //     });
// //   }, [markers]);

// //   return isLoaded ? (
// //     <GoogleMap
// //       mapContainerStyle={containerStyle}
// //       center={center}
// //       zoom={zoom}
// //       options={{
// //         streetViewControl: false,
// //         mapTypeControl: false,
// //       }}
// //     >
// //       {markers.map((marker) => (
// //         <Marker
// //           key={marker.id}
// //           position={{ lat: marker.lat, lng: marker.lng }}
// //           title={marker.name}
// //           icon={{
// //             url: MarkerIcon,
// //             scaledSize: new window.google.maps.Size(24, 24), // Define size explicitly
// //           }}
// //           draggable
// //           onClick={() => setSelectedMarker(marker)}
// //         />
// //       ))}

// //       {selectedMarker && (
// //         <InfoWindow
// //           position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
// //           onCloseClick={() => setSelectedMarker(null)}
// //         >
// //           <div>
// //             <h5>{selectedMarker.name}</h5>
// //             <p>
// //               Address:{" "}
// //               {markerAddresses[selectedMarker.id]
// //                 ? markerAddresses[selectedMarker.id]
// //                 : "Fetching address..."}
// //             </p>
// //           </div>
// //         </InfoWindow>
// //       )}
// //     </GoogleMap>
// //   ) : (
// //     <></>
// //   );
// // };

// // export default React.memo(Map);

import { getAddressByLatLng } from "./MapFunction";
import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  OverlayView
} from "@react-google-maps/api";
import MarkerIcon from "../../assets/icons/location-position.png";
import "./map.css"

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Map = ({
  center,
  markers = [], // Ensure a default value
  zoom = 9,
  width = "100%",
  height = "100%",
}) => {
  const [map, setMap] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null); // Tracks which marker is hovered
  const [newMarkers, setNewMarkers] = useState([]);

  const containerStyle = {
    width: width,
    height: height,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  // Update newMarkers whenever markers change
  useEffect(() => {
    const updateMarkers = async () => {
      try {
        const updatedMarkers = await Promise.all(
          markers.map(async (marker) => {
            try {
              const address = await getAddressByLatLng(marker);
              return {
                ...marker,
                displayName: marker.name,
                address: address,
              };
            } catch (error) {
              return {
                ...marker,
                displayName: marker.name,
                address: "Address not available",
              };
            }
          })
        );
        setNewMarkers(updatedMarkers);
        console.log("Updated newMarkers:", updatedMarkers); // Debugging
      } catch (error) {
        console.error("Error updating markers:", error);
      }
    };

    if (markers.length > 0) {
      updateMarkers();
    }
  }, [markers]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
  };

  const handleMouseOver = (marker) => {
    setHoveredMarker(marker);
  };

  const handleMouseOut = () => {
    setHoveredMarker(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
      
    >


      {newMarkers.map((marker, index) => (
        
        // <Marker
        //   key={index}
        //   position={{ lat: marker.lat, lng: marker.lng }}
        //   icon={{
        //     url: MarkerIcon,
        //     // url: <div><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s" /></div>,
        //     scaledSize: new window.google.maps.Size(44, 44),
        //   }}
        //   draggable
        //   onMouseOver={() => handleMouseOver(marker)} // Hover in
        //   onMouseOut={handleMouseOut} // Hover out
        // />

        <OverlayView
          position={{ lat: marker.lat, lng: marker.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

        >
          {/* <div class="_marker"
          onMouseOver={() => handleMouseOver(marker)}
          onMouseOut={handleMouseOut}
          >
          <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s"
              alt="Custom Marker"
              style={{ width: 44, height: 44 }}
              className="centerLayout"
              
            /> 
          </div>  */}
          <div className="custom-marker"
            onMouseOver={() => handleMouseOver(marker)}
            onMouseOut={handleMouseOut}
          >
            <div className="topLayout"></div>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s"
              alt="Custom Marker"
              style={{ width: 44, height: 44 }}
              className="centerLayout"
              onMouseOver={() => handleMouseOver(marker)}
              onMouseOut={handleMouseOut}
            />
            <div className="bottomLayout">
              <div class="v-shape">
                <div class="arm left"></div>
                <div class="arm right"></div>
              </div></div>
          </div>
        </OverlayView>
      ))}

      {hoveredMarker && (
        <InfoWindow
          position={{
            lat: hoveredMarker.lat,
            lng: hoveredMarker.lng,
          }}
          options={{ pixelOffset: new window.google.maps.Size(25, 0) }} // Offset for better positioning
        >
          <div
            style={{
              padding: "10px",
              paddingRight: "30px",
              maxWidth: "200px",
              borderRadius: "10px",
              backgroundColor: "#ffffff",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              fontFamily: "Arial, sans-serif",
            }}
            onMouseEnter={() => setHoveredMarker(hoveredMarker)} // Keep open on hover
            onMouseLeave={handleMouseOut} // Close when leaving
          >
            <h4
              style={{
                fontSize: "16px",
                color: "#333",
                textAlign: "center",
              }}
            >
              📍 {hoveredMarker.displayName}
            </h4>
            <p
              style={{
                margin: "0",
                fontSize: "14px",
                color: "#555",
                textAlign: "center",
              }}
            >
              {hoveredMarker.address}
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);
