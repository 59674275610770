import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Button from "react-bootstrap/Button";
import ButtonCom from "../../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { MdCreateNewFolder } from "react-icons/md";
import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
    commonGetData,
    commonGetDataFourth,
    commonSaveData,
    initCommonState,
} from "../../../redux/slices/commonSlice";

import {
    getAttendModule,
    initMoreSettState,
} from "../../../redux/slices/moreSettings";

const HolidayModule = () => {
    const navigate = useNavigate()
    const user = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : [];

    const dispatch = useDispatch();
    const store = useSelector((state) => state.moreSettings);
    const commonStore = useSelector((state) => state.common);

    const [moduleList, setModuleList] = useState([]);

    const getModule = useCallback(() => {
        const path = `admin/getHolidayMain?userId=${user.user_id}`;
        dispatch(getAttendModule(path));
    }, []);

    useEffect(() => {
        getModule();
    }, []);

    useEffect(() => {
        if (store.mSetGetAttModSuccess) {
            setModuleList(store.mSetGetAttModData);
        }
    }, [store.mSetGetAttModSuccess]);


    // Edit function starts here --------------------------------
    const handelEdit = (data) => {
        console.log(data.data.attendanceId);
    };
    // Delete function starts here --------------------------------
    const [show, setShow] = useState(false);
    const handleModalOpen = () => setShow(true);
    const handleModalClose = () => setShow(false);
    const [deleteId, setDeleteId] = useState(null);
    const handleDelete = (id) => {
        setDeleteId(id);
        handleModalOpen();
    };
    const handleDeleteSubmit = () => {
        let data = {
            path: "admin/deleteAttendanceModule",
            data: {
                userId: user.user_id,
                attendanceId: deleteId,
            },
        };
        dispatch(commonSaveData(data));
        handleModalClose();
    };

    // useEffect(() => {
    //     if (commonStore.commonSuccess) {
    //         toast.success(commonStore.commonMessage);
    //         getModule();
    //         handleModalClose1();
    //     }
    //     if (commonStore.commonError) {
    //         toast.error(commonStore.commonMessage);
    //     }
    // }, [commonStore.commonSuccess, commonStore.commonError]);

    // const [attendance_id, setAttendance_id] = useState("");

    // User list Variable and functions
    // const [employeeList, setEmployeeList] = useState([]);
    // const [filterEmployeeList, setFilterEmployeeList] = useState([]);

    // const getEmployeeList = useCallback((id) => {
    //     const path = `admin/getStaffAttendanceList?userId=${user.user_id}&attendanceId=${id}`;
    //     dispatch(commonGetDataFourth(path));
    // }, []);

    // useEffect(() => {
    //     if (commonStore.commonGetFourthSuccess) {
    //         setEmployeeList(commonStore.commonGetFourthData);
    //         setFilterEmployeeList(commonStore.commonGetFourthData);
    //         dispatch(initCommonState());
    //     }
    // }, [commonStore.commonGetFourthSuccess]);

    // const handleAssignId = (id) => {
    //     setAttendance_id(id);
    //     getEmployeeList(id);
    // };

    const [show1, setShow1] = useState(false);

    const handleModalClose1 = () => {
        setShow1(false);
        // setSelectedUsers([]);
    };
    const handleShow1 = () => setShow1(true);

    // const [selectedUsers, setSelectedUsers] = useState([]);
    // const [searchQuery, setSearchQuery] = useState(""); // State for search query

    // // Handle individual checkbox change
    // const selectUser = (event, userId) => {
    //     const isChecked = event.target.checked;
    //     setSelectedUsers(
    //         (prevSelected) =>
    //             isChecked
    //                 ? [...prevSelected, userId] // Add user to the list
    //                 : prevSelected.filter((id) => id !== userId) // Remove user from the list
    //     );
    // };
    // Functions and variables for create new holiday module =========================================================
    const [moduleName, setModuleName] = useState("")
    const handleChange = (event) => {
        const { target: { value } } = event
        setModuleName(value)
    }

    const createHolidayModule = (e) => {
        e.preventDefault();
        const data = {
            path: `admin/insertHolidayMain`,
            data: {
                "userId": user.user_id,
                "moduleName": moduleName
            }
        }
        dispatch(commonSaveData(data))
    };

    const heading = [
        {
            headerName: "Module Name",
            field: "moduleName",
            flex: 1,
        },

        {
            headerName: "Action",
            field: "id",
            width: 200,
            cellRenderer: (rowData) => {
                return (
                    <>
                        <div className="Branches-table-button">
                            {/* <MdCreateNewFolder
                                style={{
                                    cursor: "pointer",
                                    fontSize: 25,
                                    color: "#58d68d",
                                    marginRight: "10px",
                                }}
                                onClick={() => {
                                    handleShow2();
                                }}
                            /> */}
                            <Link to={`/attendance-settings-holiday/${rowData.data.moduleId}`}>
                                <CiEdit
                                    style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                                    onClick={() => {
                                        handelEdit(rowData);
                                    }}
                                />
                            </Link>

                            <MdOutlineDeleteSweep
                                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                                onClick={() => handleDelete(rowData.data.attendanceId)}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    // Actions afer methods executed ==========================================================
    useEffect(() => {
        if (commonStore.commonSuccess) {
            toast.success(commonStore.commonMessage);
            getModule();
            handleModalClose1();
            if (commonStore.commonData.id) {
                navigate(`/attendance-settings-holiday/${commonStore.commonData.id}`)
            }
        }
        if (commonStore.commonError) {
            toast.error(commonStore.commonMessage);
        }
    }, [commonStore.commonSuccess, commonStore.commonError]);
    //===============================
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [10, 20, 50, 100];



    // // Assign Holiday To Employee ========================================================

    // const [show2, setShow2] = useState(false);

    // const handleModalClose2 = () => {
    //     setShow2(false);
    //     // setSelectedUsers([]);
    // };
    // const handleShow2 = () => setShow2(true);
    // const [moduleId, setModuleId] = useState("")
    // const [selectedUsers, setSelectedUsers] = useState([]);
    // const [searchQuery, setSearchQuery] = useState(""); // State for search query

    // // Handle individual checkbox change
    // const selectUser = (event, userId) => {
    //     const isChecked = event.target.checked;
    //     setSelectedUsers(
    //         (prevSelected) =>
    //             isChecked
    //                 ? [...prevSelected, userId] // Add user to the list
    //                 : prevSelected.filter((id) => id !== userId) // Remove user from the list
    //     );
    // };

    // // Handle "Select All" checkbox change
    // const SelectAll = (event) => {
    //     const isChecked = event.target.checked;
    //     if (isChecked) {
    //         const allUserIds = employeeList.map((row) => row.staffId); // Get all user IDs
    //         setSelectedUsers(allUserIds); // Select all users
    //     } else {
    //         setSelectedUsers([]); // Deselect all users
    //     }
    // };

    // // Determine if "Select All" should be checked
    // const isSelectAll =
    //     selectedUsers.length === employeeList.length && employeeList.length > 0;

    // // Filter employees based on the search query
    // const searchEmployees = (val) => {
    //     setSearchQuery(val);
    //     const filteredEmployees = filterEmployeeList.filter((v) =>
    //         v.full_name.toLowerCase().includes(val.toLowerCase())
    //     );
    //     setEmployeeList(filteredEmployees);
    // };

    // const handleAssignShiftSubmit = (e) => {
    //     e.preventDefault();
    //     const data = {
    //         path: "admin/assignHolidayEmployee",
    //         data: {
    //             userId: user.user_id,
    //             staffId: selectedUsers.toString(),
    //             moduleId: moduleId,
    //         },
    //     };
    //     dispatch(commonSaveData(data));
    // };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                className="toast-custom"
            />

             {/* <Modal show={show2} onHide={handleModalClose2} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Assign Employee to this Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
                    <input
                        className="search-employee"
                        type="text"
                        placeholder="Search employees..."
                        value={searchQuery}
                        onChange={(e) => searchEmployees(e.target.value)}
                    />

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={isSelectAll}
                                        onChange={SelectAll}
                                        id="sel-all"
                                        className="colored-checkbox"
                                    />
                                    &nbsp; &nbsp;
                                    <label style={{ cursor: "pointer" }} htmlFor="sel-all">
                                        Select All
                                    </label>
                                </th>
                                <th>Employee Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeList.length > 0 ? (
                                employeeList.map((row) => (
                                    <tr key={row.staffId}>
                                        <td>
                                            
                                            <input
                                                type="checkbox"
                                                checked={
                                                    row.shiftAssignedStatus == "1" ||
                                                    selectedUsers.includes(row.staffId)
                                                }
                                                onChange={(e) => selectUser(e, row.staffId)}
                                                id={"shift" + row.staffId}
                                                className="colored-checkbox"
                                            />
                                        </td>
                                        <td style={{ display: 'flex', flexDirection: "column", gap: "5px" }}>
                                            <label
                                                style={{ cursor: "pointer" }}
                                                htmlFor={"shift" + row.staffId}
                                            >
                                                {row.full_name}
                                            </label>
                                            <span style={{ fontSize: "12px", color: "gray" }}>{row.employee_id}</span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2">No employees found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            backgroundColor: "#566573",
                            color: "#fff",
                            marginRight: "5px",
                        }}
                        onClick={handleModalClose2}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: "#5dade2", color: "#fff" }}
                        onClick={handleAssignShiftSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>  */}

            {/* Modal for create new holiday module */}
            <Modal show={show1} onHide={handleModalClose1} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Create new module</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Group>
                        <Form.Control placeholder="Eneter holiday module name" value={moduleName} name="moduleName" onChange={handleChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{
                            backgroundColor: "#566573",
                            color: "#fff",
                            marginRight: "5px",
                        }}
                        onClick={handleModalClose1}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ backgroundColor: "#5dade2", color: "#fff" }}
                        onClick={createHolidayModule}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleModalClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Module</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure! to delete this record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSubmit}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="SettingBody">
                <div className="SettingBody-head">
                    <Link to={"/setting/"}>
                        <ArrowBackIcon sx={{ scale: 1 }} />
                    </Link>
                    <p>Holiday Module</p>
                </div>

                <div className="SettingBody-card mt-3">
                    <div className="Branches-body" style={{ borderRadius: "7px" }}>
                        <div className="Branches-btn">

                            <ButtonCom
                                type="button"
                                class_name="p_btn"
                                text={"Create Holiday Module"}
                                url_or_onclick={handleShow1}
                                icon={<AddIcon />}
                                width={""}
                            />

                        </div>
                        <div
                            className="ag-theme-quartz" // applying the Data Grid theme
                            style={{ height: 350, width: "100%" }}
                        >
                            <AgGridReact
                                rowData={moduleList}
                                columnDefs={heading}
                                omLayout="autoHeight"
                                pagination={true}
                                paginationPageSize={paginationPageSize}
                                paginationPageSizeSelector={paginationPageSizeSelector}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(HolidayModule);
