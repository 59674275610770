import "./button.css";
import React from "react";
import { Link } from "react-router-dom";

const button = ({
  type,
  class_name,
  text,
  url_or_onclick,
  icon = null,
  width,
}) => {
  return type == "link" ? (
    <Link to={url_or_onclick} className={class_name} style={{ width }}>
      {text}
    </Link>
  ) : (
    <button
      type="type"
      onClick={url_or_onclick}
      className={class_name}
      style={{ width }}
    >
     {icon} { text }
    </button>
  );
};

export default button;
