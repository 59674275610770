import popperClasses from "@mui/material/Popper/popperClasses";
import "./MoreSetting.css";
import React, { useCallback, useEffect, useState } from "react";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { RiDeleteBin6Line } from "react-icons/ri";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdFingerprint } from "react-icons/md";
import Switch from "@mui/material/Switch";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CustomButton from "../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
// mui components
import { FaRegSquarePlus } from "react-icons/fa6";
import { FiMinusSquare } from "react-icons/fi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Modal from "react-bootstrap/Modal";
import { MultiSelect } from 'react-multi-select-component';

import { useDispatch, useSelector } from "react-redux";
import {
  getAttendModule,
  getAttendOptions,
  getMoreSettings,
  saveMoreSettings,
  initMoreSettState,
} from "../../redux/slices/moreSettings";
import {
  commonGetData,
  commonGetDataOne,
  commonGetDataTwo,
  commonGetDataThird,
  commonGetDataFourth,
  commonSaveData,
  initState,
  initCommonState,
} from "../../redux/slices/commonSlice";
import {
  initEmpState,
  getEmployeeData,
} from "../../redux/slices/employeeSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
const animatedComponents = makeAnimated();
const UpdateSettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const store = useSelector((state) => state.moreSettings);
  const commonSateStore = useSelector((state) => state.common);

  // Tabs variables ===========================
  const settingTabs = [
    {
      id: 0,
      tabName: "General Setting",
      childTabs: [],
    },
    {
      id: 1,
      tabName: "Regularization",
      childTabs: [],
    },
    {
      id: 2,
      tabName: "Permission Settings",
      childTabs: [],
    },
    {
      id: 3,
      tabName: "Approval Chain",
      childTabs: [],
    },

    {
      id: 4,
      tabName: "Auto Half Day",
      childTabs: [],
    },
  ];

  const [mainTabIndex, setMainTabIndex] = useState(0);
  // Attend option variables and functions
  const [attendOptions, setAttendOptions] = useState([]);
  // Get more settings prefill data variables and function
  const [moreSettingsData, setMoreSettingsData] = useState([]);

  const fetchAttendOptions = useCallback(() => {
    let path = "admin/getAttendOptions";
    dispatch(getAttendOptions(path));
  }, []);

  useEffect(() => {
    fetchAttendOptions();
  }, []);

  useEffect(() => {
    if (store.mSetGetAttSuccess) {
      setAttendOptions(store.mSetGetAttData);
    }
  }, [store.mSetGetAttSuccess]);

  const [state, setState] = useState({
    userId: user.user_id,
    moduleId: "",
    moduleName: "",
    // ClockInOut ---------------------------
    employeeLocation: "",
    // AttendanceRegularization -------------
    regularizeAttendDays: "",
    regularizeAttendYear: "",
    reasonForPermission: "",
    reqularizeUpto: "",
    reasonForLate: "",
    // LateCheckInEarlyCheckOut -------------
    permissionAvailDays: "",
    permissionAvailYears: "",
    reasonForPermission: "",
    lateArrivalMinutes: "",
    reasonForLate: "",
    earlyCheckoutMinutes: "",
    reasonForEarly: "",
    // Approval Chain
    mandatoryApprovalRequest: "",
    mandatoryApprovalYears: "",
    // GeneralSetting -------------
    gracePeriodHours: "",
    allowEmpRegAfterClockOut: "",
    enableOvertimePolicy: "",
    offSitePermissions: "",
    offSitePermissionEnable: "",
    isEnableRegularizeSettings: "",
    empRequestRegularize: "", // Weekly,Monthly,yearly
    regularizePeriodMax: "",
    regularizeAttendDays: "",
    regularizeAttendDate: "",
    exceedRegularizeRequest: "",
    orderOfLeaveRequest: "", //Array should be store as a string
    isFaceEnable: "",
    isLocationEnable: "",
    autoClockOut: "",
    offSiteEnable: "",
    onSiteReasonList: [
      {
        reasonName: "",
      },
    ],
    regularizeReasonList: [
      {
        reasonName: "",
      },
    ],
    permissionReasonList: [
      {
        reasonName: "",
      },
    ],
    lateReasonList: [
      {
        reasonName: "",
      },
    ],
    earlyCheckOutReasonList: [
      {
        reasonName: "",
      },
    ],
    trackLocationTime: "",
    reasonForPermission: "",
    // AutoHalfDay ----------------
    allowHalfDays: "",
    allowHalfDaysYears: "",
    approvalSettings: [
      {
        staffId: "",
        assigneeName: "",
        roleId: "",
        isSkipStep: false,
        skipStep: 0,
      },
    ],
    moduleError: false,
  });

  // GET STORED ATTENDANCE DATA FROM DATABASE
  const getAttendanceDetails = useCallback(() => {
    let url = `admin/getAttendanceGeneralSettings?moduleId=${params.id}`;
    dispatch(commonGetDataFourth(url));
  }, []);
  useEffect(() => {
    getAttendanceDetails();
  }, []);

  const [saveLeaves, setSaveLeaves] = useState({
    list: [],
  });

  useEffect(() => {
    if (commonSateStore.commonGetFourthSuccess) {
      if (commonSateStore.commonGetFourthData.moduleName) {
        const data = commonSateStore.commonGetFourthData;

        // Prepare updated state object
        const updatedState = {
          ...state,
          moduleId: params.id,
          moduleName: data.moduleName,
          userId: user.user_id,
          // ClockInOut
          employeeLocation: data.employeeLocation,
          // AttendanceRegularization
          regularizeAttendDays: data.regularizeAttendDays,
          regularizeAttendYear: data.regularizeAttendYear,
          reasonForPermission: data.reasonForPermission,
          reqularizeUpto: data.reqularizeUpto,
          reasonForLate: data.reasonForLate,
          // LateCheckInEarlyCheckOut
          permissionAvailDays: data.permissionAvailDays,
          permissionAvailYears: data.permissionAvailYears,
          lateArrivalMinutes: data.lateArrivalMinutes,
          earlyCheckoutMinutes: data.earlyCheckoutMinutes,
          reasonForEarly: data.reasonForEarly,
          // Approval Chain
          mandatoryApprovalRequest: data.mandatoryApprovalRequest,
          mandatoryApprovalYears: data.mandatoryApprovalYears,
          // GeneralSetting
          gracePeriodHours: data.gracePeriodHours,
          allowEmpRegAfterClockOut: data.allowEmpRegAfterClockOut,
          enableOvertimePolicy: data.enableOvertimePolicy,
          offSitePermissions: data.offSitePermissions,
          offSitePermissionEnable: data.offSitePermissionEnable,
          isEnableRegularizeSettings: data.isEnableRegularizeSettings,
          empRequestRegularize: data.empRequestRegularize,
          regularizePeriodMax: data.regularizePeriodMax,
          regularizeAttendDate: data.regularizeAttendDate,
          exceedRegularizeRequest: data.exceedRegularizeRequest,
          orderOfLeaveRequest: data.orderOfLeaveRequest,
          isFaceEnable: data.isFaceEnable,
          isLocationEnable: data.isLocationEnable,
          autoClockOut: data.autoClockOut,
          offSiteEnable: data.offSiteEnable,
          trackLocationTime: data.trackLocationTime,
          allowHalfDays: data.allowHalfDays,
          allowHalfDaysYears: data.allowHalfDaysYears,
        };

        // Conditionally add additional properties
        if (
          Array.isArray(data?.onSiteReasonList) &&
          data.onSiteReasonList.length > 0
        ) {
          updatedState.onSiteReasonList = data.onSiteReasonList;
        }
        if (
          Array.isArray(data?.onRegularizeReasonList) &&
          data.onRegularizeReasonList.length > 0
        ) {
          updatedState.regularizeReasonList = data.onRegularizeReasonList;
        }
        if (
          Array.isArray(data?.onPermissionReasonList) &&
          data.onPermissionReasonList.length > 0
        ) {
          updatedState.permissionReasonList = data.onPermissionReasonList;
        }
        if (
          Array.isArray(data?.onLateReasonList) &&
          data.onLateReasonList.length > 0
        ) {
          updatedState.lateReasonList = data.onLateReasonList;
        }
        if (
          Array.isArray(data?.onEarlyCheckOutReasonList) &&
          data.onEarlyCheckOutReasonList.length > 0
        ) {
          updatedState.earlyCheckOutReasonList = data.onEarlyCheckOutReasonList;
        }
        if (Array.isArray(data?.approvalList) && data.approvalList.length > 0) {
          const newArr = [];

          data.approvalList.map((v) => {
            if (v.skipStep == "0") {
              newArr.push({
                staffId: v.staffId,
                assigneeName: v.assignName,
                roleId: v.roleId,
                isSkipStep: false,
                skipStep: v.skipStep,
              });
            } else {
              newArr.push({
                staffId: v.staffId,
                assigneeName: v.assignName,
                roleId: v.roleId,
                isSkipStep: true,
                skipStep: v.skipStep,
              });
            }
          });

          if (newArr) {
            updatedState.approvalSettings = newArr;
          }
        }

        // Update state in a single call
        setState(updatedState);
        dispatch(initCommonState());
      }
    }
  }, [commonSateStore.commonGetFourthSuccess]);
  // GET STORED ATTENDANCE DATA FROM DATABASE

  // const [saveLeaves, setSaveLeaves] = useState({
  //   list: [],
  // });
  const [leaveList, setLeaveList] = useState([]);

  const getLeaveList = useCallback(() => {
    let path = `admin/fetchLeave?userId=${user.user_id}`;
    dispatch(commonGetDataOne(path));
  }, []);

  useEffect(() => {
    getLeaveList();
  }, []);

  useEffect(() => {
    if (commonSateStore.commonGetOneSuccess) {
      if (
        Array.isArray(commonSateStore.commonGetOneData) &&
        commonSateStore.commonGetOneData.length > 0
      ) {
        const listArray = commonSateStore.commonGetOneData.map((val) => {
          return {
            label: val.leave_name + " (" + val.leave_balance + ")",
            value: val.leaveId,
          };
        });
        setLeaveList(listArray);
      }
    }
  }, [commonSateStore.commonGetOneSuccess]);

  const handleLeavesChange = (e) => {
    setSaveLeaves({
      ...saveLeaves,
      list: e,
    });
    setState({
      ...state,
      moduleError: false,
    });
  };

  const handleRegularizeChange = (e) => {
    const {
      target: { name, checked },
    } = e;
    setState({
      ...state,
      isEnableRegularizeSettings: checked ? 1 : 0,
    });
  };

  const getModuleData = () => {
    let url = `admin/getAttendanceModule?userId=${user.user_id}`;
    // dispatch(getAttendModule(url));
    dispatch(commonGetDataTwo(url));
  };
  useEffect(() => {
    getModuleData();
  }, []);

  const mainHandleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };
  const mainHandleChecked = (event) => {
    const {
      target: { name, checked },
    } = event;
    setState({
      ...state,
      [name]: checked ? 1 : 0,
    });
  };

  const beforeSubmitSettingData = () => {
    const elm = document.getElementById("submit-module");
    if (elm) {
      elm.click();
    }
  };

  const [validated, setValidated] = useState(false);

  const submitSettingData = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: {
          userId: user.user_id,
          moduleId: state.moduleId,
          moduleName: state.moduleName,
          // ClockInOut ---------------------------
          employeeLocation: state.employeeLocation,
          // AttendanceRegularization -------------
          regularizeAttendDays: state.regularizeAttendDays,
          regularizeAttendYear: state.regularizeAttendYear,
          reasonForPermission: state.reasonForPermission,
          reqularizeUpto: state.reqularizeUpto,
          reasonForLate: state.reasonForLate,
          // LateCheckInEarlyCheckOut -------------
          permissionAvailDays: state.permissionAvailDays,
          permissionAvailYears: state.permissionAvailYears,
          reasonForPermission: state.reasonForPermission,
          lateArrivalMinutes: state.lateArrivalMinutes,
          reasonForLate: state.reasonForLate,
          earlyCheckoutMinutes: state.earlyCheckoutMinutes,
          reasonForEarly: state.reasonForEarly,
          // Approval Chain
          mandatoryApprovalRequest: state.mandatoryApprovalRequest,
          mandatoryApprovalYears: state.mandatoryApprovalYears,
          // GeneralSetting -------------
          gracePeriodHours: state.gracePeriodHours,
          allowEmpRegAfterClockOut: state.allowEmpRegAfterClockOut,
          enableOvertimePolicy: state.enableOvertimePolicy,
          offSitePermissions: state.offSitePermissions,
          offSitePermissionEnable: state.offSitePermissionEnable,
          isEnableRegularizeSettings: state.isEnableRegularizeSettings,
          empRequestRegularize: state.empRequestRegularize, // Weekly,Monthly,yearly
          regularizePeriodMax: state.regularizePeriodMax,
          regularizeAttendDays: state.regularizeAttendDays,
          regularizeAttendDate: state.regularizeAttendDate,
          exceedRegularizeRequest: state.exceedRegularizeRequest,
          orderOfLeaveRequest: saveLeaves.list
            .map((v) => {
              return v.value;
            })
            .toString(), //Array should be store as a string
          isFaceEnable: state.isFaceEnable,
          isLocationEnable: state.isLocationEnable,
          autoClockOut: state.autoClockOut,
          offSiteEnable: state.offSiteEnable,

          onSiteReasonList: state.onSiteReasonList,
          regularizeReasonList: state.regularizeReasonList,
          permissionReasonList: state.permissionReasonList,
          lateReasonList: state.lateReasonList,
          earlyCheckOutReasonList: state.earlyCheckOutReasonList,

          trackLocationTime: state.trackLocationTime,
          reasonForPermission: state.reasonForPermission,
          // AutoHalfDay ----------------
          allowHalfDays: state.allowHalfDays,
          allowHalfDaysYears: state.allowHalfDaysYears,
          approvalSettings: state.approvalSettings,
        },
      };
      console.log(data);
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
      setTimeout(() => {
        navigate("/attendance-setting");
      }, 2000);
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <div className="more-setting-container" style={{ position: "relative", height: "200%" }}>
        <h4 className="more-setting-title">
          <Link to="/attendance-setting">
            <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
          </Link>
          {mainTabIndex == 0 && settingTabs[0].tabName}
          {mainTabIndex == 1 && settingTabs[1].tabName}
          {mainTabIndex == 2 && settingTabs[2].tabName}
          {mainTabIndex == 3 && settingTabs[3].tabName}
          {mainTabIndex == 4 && settingTabs[4].tabName}
        </h4>

        <div className="main-tab">
          {settingTabs.map((tab, idx) => {
            return (
              <div className="inner-tab">
                <button
                  className={mainTabIndex == tab.id ? "active" : null}
                  onClick={() => setMainTabIndex(idx)}
                  key={idx}
                >
                  {idx + 1}
                </button>
                <span>{tab.tabName}</span>
              </div>
            );
          })}
        </div>
        {/* ----------------- */}
        <div className="main-tab-body" style={{ position: "relative", backgroundColor: "white", height: "1000px" }}>
          {
            <div
              style={{
                display: mainTabIndex == 0 ? "block" : "none",
                backgroundColor: "#fff",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  width: mainTabIndex == 0 ? "58.4%" : "60%",
                  margin: "0 auto",
                }}
              >
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={submitSettingData}
                >
                  <Form.Group>
                    <Form.Control
                      name="moduleName"
                      value={state.moduleName}
                      onChange={mainHandleChange}
                      placeholder="Enter module name"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Module name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <button
                    type="submit"
                    id="submit-module"
                    style={{ display: "none" }}
                  ></button>
                </Form>
              </div>
            </div>
          }

          <div style={{ display: mainTabIndex == 0 ? "block" : "none" }}>
            <GeneralSetting
              mainHandleChange={mainHandleChange}
              mainHandleChecked={mainHandleChecked}
              setMainTabIndex={setMainTabIndex}
              mainState={state}
              setMainState={setState}
            />
          </div>

          <div style={{ display: mainTabIndex == 1 ? "block" : "none", height: "auto" }}>
            <AttendanceRegularization
              attendOptions={attendOptions}
              mainHandleChange={mainHandleChange}
              mainState={state}
              setMainTabIndex={setMainTabIndex}
              setMainState={setState}
              handleRegularizeChange={handleRegularizeChange}
              // leaveList={leaveList}
              handleLeavesChange={handleLeavesChange}
              saveLeaves={saveLeaves}
              setSaveLeaves={setSaveLeaves}
            />
          </div>
          <div style={{ display: mainTabIndex == 2 ? "block" : "none" }}>
            <LateCheckInEarlyCheckOut
              attendOptions={attendOptions}
              mainHandleChange={mainHandleChange}
              mainState={state}
              setMainTabIndex={setMainTabIndex}
              setMainState={setState}
            />
          </div>
          <div style={{ display: mainTabIndex == 3 ? "block" : "none" }}>
            <ApprovalChain
              attendOptions={attendOptions}
              mainHandleChange={mainHandleChange}
              setMainTabIndex={setMainTabIndex}
              mainState={state}
              setMainState={setState}
            />
          </div>

          <div style={{ display: mainTabIndex == 4 ? "block" : "none" }}>
            <AutoHalfDay
              mainHandleChange={mainHandleChange}
              setMainTabIndex={setMainTabIndex}
              mainState={state}
              beforeSubmitSettingData={beforeSubmitSettingData}
              store={store}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const GeneralSetting = ({
  mainHandleChange,
  mainHandleChecked,
  setMainTabIndex,
  mainState,
  setMainState,
}) => {
  const handleChecked = (event) => {
    const {
      target: { name, checked },
    } = event;
  };
  const addOnSiteReason = () => {
    const newArr = [...mainState.onSiteReasonList];
    newArr.push({ reasonName: "" });
    setMainState({
      ...mainState,
      onSiteReasonList: newArr,
    });
  };

  const removeOnSiteReason = (idx) => {
    const newArr = [...mainState.onSiteReasonList];
    newArr.splice(idx, 1);
    setMainState({
      ...mainState,
      onSiteReasonList: newArr,
    });
  };

  const handleOnSiteReason = (e, idx) => {
    const {
      target: { value },
    } = e;
    const newArr = [...mainState.onSiteReasonList];
    newArr[idx].reasonName = value;
    setMainState({
      ...mainState,
      onSiteReasonList: newArr,
    });
  };

  const submitHandle = (e) => {
    e.preventDefault();
  };

  const handleRadioBtn = (e) => {
    const { value } = e.target;
    setMainState({
      ...mainState,
      offSitePermissions: value,
    });
  };

  const handleYesNo = (e) => {
    const { checked } = e.target;
    if (checked) {
      setMainState({
        ...mainState,
        offSitePermissionEnable: "1",
      });
    } else {
      setMainState({
        ...mainState,
        offSitePermissionEnable: "0",
      });
    }
  };

  return (
    <div className="more-setting-form-container">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <Form
        className="form"
        onSubmit={submitHandle}
        style={{ padding: "0 10px", paddingBottom: "30px" }}
      >
        <Form.Group className="mb-4">
          <Row>
            <Col sm="12" className="flex-form">
              <div
                // className="AttendanceRules-tap clockinout"
                style={{ cursor: "default" }}
              >
                <b>
                  Mark Attendance form
                  <div className="MarkAttendanceform-radio-card">
                    <div className="MarkAttendanceform-radio">
                      <input
                        name="employeeLocation"
                        type="radio"
                        value="1"
                        onChange={(e) => {
                          mainHandleChange(e);
                        }}
                        checked={
                          mainState.employeeLocation == "1" ? "checked" : null
                        }
                      />
                      <label>Only in Assigned Location</label>
                    </div>
                    <div className="MarkAttendanceform-radio">
                      <input
                        name="employeeLocation"
                        type="radio"
                        value="2"
                        onChange={(e) => {
                          mainHandleChange(e);
                        }}
                        checked={
                          mainState.employeeLocation == "2" ? "checked" : null
                        }
                      />
                      <label>From Anywhere</label>
                    </div>
                  </div>
                </b>
              </div>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col
              sm={12}
              className="flex-form mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <label>Enable AI Face Recognition Setting &nbsp;</label>
              <Switch
                name="isFaceEnable"
                checked={mainState.isFaceEnable == "1" ? true : false}
                onChange={(e) => {
                  handleChecked(e);
                  mainHandleChecked(e);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
          </Row>
          <Form.Control.Feedback type={"invalid"}>
            Field is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col
              sm={12}
              className="flex-form mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <label>Track Location Permission &nbsp;</label>
              <Switch
                name="isLocationEnable"
                checked={mainState.isLocationEnable == "1" ? true : false}
                onChange={(e) => {
                  handleChecked(e);
                  mainHandleChecked(e);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
          </Row>
          <Form.Control.Feedback type={"invalid"}>
            Field is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col sm={12} className="flex-form mb-3">
              <label>Track Location Every &nbsp;</label>
              <Form.Control
                name="trackLocationTime"
                value={mainState.trackLocationTime}
                onChange={(e) => {
                  mainHandleChange(e);
                }}
                style={{ width: "200px" }}
                placeholder="Enter minutes"
              />
              <label> &nbsp;&nbsp;Minutes</label>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col
              sm={12}
              className=" mb-3"
              style={{
                border: "1px solid lightgray",
                borderRadius: "7px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Enable Auto Clock-Out &nbsp;</label>
                <Switch
                  name="autoClockOut"
                  checked={mainState.autoClockOut == "1" ? true : false}
                  onChange={(e) => {
                    handleChecked(e);
                    mainHandleChecked(e);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>

              <span
                style={{
                  position: "relative",
                  marginTop: "10px",
                  fontSize: "14px",
                  color: "gray",
                }}
              >
                Automatically clock out employees if they fail to clock out
                after their shift ends.
              </span>
            </Col>
          </Row>
          <Form.Control.Feedback type={"invalid"}>
            Field is required
          </Form.Control.Feedback>
        </Form.Group>

        {mainState.autoClockOut == "1" && (
          <div
            className={
              mainState.autoClockOut == "1"
                ? "drop-container"
                : "container-hide"
            }
          >
            <Form.Group>
              <Row>
                <Col
                  sm={12}
                  className="mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label style={{ textWrap: "nowrap" }}>
                      Grace Period (in hours) &nbsp;
                    </label>
                    <Form.Control
                      name="gracePeriodHours"
                      placeholder="Enter number of hours (e.g., 2)"
                      onChange={mainHandleChange}
                      value={mainState.gracePeriodHours}
                    />
                  </div>
                  <span
                    style={{
                      position: "relative",
                      top: "5px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Specify the number of hours after shift end for auto
                    clock-out to activate.
                  </span>
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Field is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col
                  sm={12}
                  className=" mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>
                      Allow Employees to Regularize Attendance After Auto
                      Clock-Out &nbsp;
                    </label>
                    <Switch
                      name="allowEmpRegAfterClockOut"
                      checked={
                        mainState.allowEmpRegAfterClockOut == "1" ? true : false
                      }
                      onChange={(e) => {
                        handleChecked(e);
                        mainHandleChecked(e);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <span
                    style={{
                      position: "relative",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Do Not Allow Regularization After Auto Clock-Out.
                  </span>
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Field is required
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col
                  sm={12}
                  className=" mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Enable Overtime Policy Exception &nbsp;</label>
                    <Switch
                      name="enableOvertimePolicy"
                      checked={
                        mainState.enableOvertimePolicy == "1" ? true : false
                      }
                      onChange={(e) => {
                        handleChecked(e);
                        mainHandleChecked(e);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <span
                    style={{
                      position: "relative",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Exclude employees with assigned overtime policies from Auto
                    Clock-Out.
                  </span>
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Field is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}

        <Form.Group>
          <Row>
            <Col
              sm={12}
              className="flex-form mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <label>Enable Off Site &nbsp;</label>
              <Switch
                name="offSiteEnable"
                checked={mainState.offSiteEnable == "1" ? true : false}
                onChange={(e) => {
                  handleChecked(e);
                  mainHandleChecked(e);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
          </Row>
          <Form.Control.Feedback type={"invalid"}>
            Field is required
          </Form.Control.Feedback>
        </Form.Group>

        {mainState.offSiteEnable == 1 && (
          <div
            className={
              mainState.offSiteEnable == 1 ? "drop-container" : "container-hide"
            }
          >
            {mainState.onSiteReasonList.map((row, idx) => {
              return (
                <Form.Group key={idx}>
                  <Row>
                    <Col sm={12} className="flex-form mb-3">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Form.Control
                          name="reasonName"
                          value={row.reasonName}
                          onChange={(e) => {
                            handleOnSiteReason(e, idx);
                          }}
                          placeholder="Reason for on site"
                          required
                        />
                        {idx == 0 && (
                          <FaRegSquarePlus
                            onClick={addOnSiteReason}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "skyblue",
                            }}
                          />
                        )}
                        {idx != 0 && (
                          <FiMinusSquare
                            onClick={() => removeOnSiteReason(idx)}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type={"invalid"}>
                    Reason is required.
                  </Form.Control.Feedback>
                </Form.Group>
              );
            })}

            <Form.Group>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  gap: "10px",
                }}
              >
                <Form.Check
                  type="radio"
                  name="offSitePermissions"
                  id="offSitePermissions1"
                  value={"1"}
                  label={
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="offSitePermissions1"
                    >
                      Require employees to return to the office to log out.
                    </label>
                  }
                  onChange={handleRadioBtn}
                  checked={mainState.offSitePermissions == "1" ? true : false}
                />
                <Form.Check
                  type="radio"
                  name="offSitePermissions"
                  id="offSitePermissions2"
                  value={"2"}
                  label={
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="offSitePermissions2"
                    >
                      Allow employees to log out from any location.
                    </label>
                  }
                  onChange={handleRadioBtn}
                  checked={mainState.offSitePermissions == "2" ? true : false}
                />
                <Form.Check
                  type="radio"
                  name="offSitePermissions"
                  id="offSitePermissions3"
                  value={"3"}
                  label={
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="offSitePermissions3"
                    >
                      Mandatory Approval for Off Site Requests:
                    </label>
                  }
                  onChange={handleRadioBtn}
                  checked={mainState.offSitePermissions == "3" ? true : false}
                />
              </div>
              <span
                style={{
                  position: "relative",
                  fontSize: "13px",
                  color: "gray",
                }}
              >
                All Off Site requests will require manager approval. Managers
                must approve or reject each request based on tracked details.
              </span>
              <br />
              <br />

              {mainState.offSitePermissions && (
                <>
                  <span
                    style={{
                      marginTop: "20px",
                      fontSize: "13px",
                      color: "gray",
                    }}
                  >
                    If this option is disabled, the system will not deduct any
                    hours or apply LOP for Off Site requests, and they will be
                    automatically approved without manager intervention.
                  </span>
                  <Form.Check
                    name="offSitePermissionEnable"
                    onChange={handleYesNo}
                    label="Yes / No"
                    checked={
                      mainState.offSitePermissionEnable == "1" ? true : false
                    }
                  />
                  {mainState.offSitePermissionEnable == "1" && (
                    <span
                      style={{
                        marginTop: "20px",
                        fontSize: "13px",
                        color: "gray",
                      }}
                    >
                      Note: Disabling this feature means employees on Off Site
                      will not be subject to attendance or leave deductions
                      regardless of their geofence activity.
                    </span>
                  )}
                </>
              )}
            </Form.Group>
          </div>
        )}

        {/* BIOMETRIC SECTION =========================== */}
        <Form.Group>
          <Row>
            <Col
              sm={12}
              className="flex-form mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <label>
                <MdFingerprint /> Biometric Attendance &nbsp;
              </label>
              <Switch
                checked={false}
                onChange={() => {
                  mainHandleChange;
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col
            sm="12"
            style={{
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              type={"button"}
              class_name="p_btn"
              text={"Next"}
              url_or_onclick={() => setMainTabIndex(1)}
              icon={null}
              width="150px"
            />
          </Col>
        </Row>

        {/* <Row>
            <Col sm="3" style={{ padding: "0" }}>
              <CustomButton
                type={"submit"}
                class_name="p_btn"
                text={store.mSetLoading ? "Submitting..." : "Submit"}
                url_or_onclick={null}
                icon={null}
                width="150px"
              />
            </Col>
          </Row> */}
      </Form>
    </div>
  );
};

const AttendanceRegularization = ({
  attendOptions,
  mainHandleChange,
  mainState,
  setMainTabIndex,
  setMainState,
  handleRegularizeChange,
  // leaveList,
  handleLeavesChange,
  saveLeaves,
  setSaveLeaves,
}) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const commonStore = useSelector((state) => state.common);
  const addRegularizePermissionReason = () => {
    const newArr = [...mainState.regularizeReasonList];
    newArr.push({ reasonName: "" });
    setMainState({ ...mainState, regularizeReasonList: newArr });
  };
  const removeRegularizePermissionReason = (index) => {
    const newArr = [...mainState.regularizeReasonList];
    newArr.splice(index, 1);
    setMainState({ ...mainState, regularizeReasonList: newArr });
  };
  const handleRegularizePermission = (e, index) => {
    const {
      target: { name, value },
    } = e;
    const newArr = [...mainState.regularizeReasonList];
    newArr[index][name] = value;
    setMainState({ ...mainState, regularizeReasonList: newArr });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [selectedLeaves, setSelectedLeaves] = useState([]);
  const [leaveList, setLeaveList] = useState([]);

  const getLeaveList = useCallback(() => {
    let path = `admin/fetchLeave?userId=${user.user_id}`;
    dispatch(commonGetDataOne(path));
  }, []);

  useEffect(() => {
    getLeaveList();
  }, []);

  useEffect(() => {
    if (commonStore.commonGetOneSuccess) {
      if (
        Array.isArray(commonStore.commonGetOneData) &&
        commonStore.commonGetOneData.length > 0
      ) {
        const listArray = commonStore.commonGetOneData.map((val) => {
          return {
            label: val.leave_name + " (" + val.leave_balance + ")",
            value: val.leaveId,
          };
        });
        setLeaveList(listArray);
        const dateArray = mainState.orderOfLeaveRequest.split(",");
        if (Array.isArray(dateArray)) {
          const selected = dateArray
            .map((v) =>
              listArray.find((obj) => parseInt(obj.value) === parseInt(v))
            )
            .filter(Boolean);
          setSaveLeaves({ ...saveLeaves, list: selected })
          setSelectedLeaves(selected);
        }
      }
    }
  }, [commonStore.commonGetOneSuccess]);


  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  return (
    <div className="more-setting-form-container" >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <Form className="form" onSubmit={handleSubmit}>
        <Form.Group>
          <Row>
            <Form.Label>Regularization Policy Settings</Form.Label>

            <Col
              sm="12"
              className="flex-form mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <span>
                Allow employees to regularise attendance when discrepancies
                occur
              </span>

              <Switch
                label="Check"
                checked={
                  mainState.isEnableRegularizeSettings == "1" ? true : false
                }
                onChange={handleRegularizeChange}
              />
            </Col>

            {mainState.isEnableRegularizeSettings == 1 && (
              <div
                className={
                  mainState.isEnableRegularizeSettings == 1
                    ? "drop-container"
                    : null
                }
              >
                <Col
                  sm={12}
                  className="mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <label>
                      Select how often employees can request regularization.
                      &nbsp;
                    </label>
                    &nbsp;&nbsp;
                    <Form.Select
                      name="empRequestRegularize"
                      value={mainState.empRequestRegularize}
                      onChange={(e) => {
                        mainHandleChange(e);
                      }}
                      style={{ width: "270px" }}
                    >
                      <option value="">Select an option</option>
                      {attendOptions.map((row, idx) => {
                        return (
                          <option value={row.id} key={idx}>
                            {row.options}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  {/* <span
                    style={{
                      position: "relative",
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Select how often employees can request regularization.
                  </span> */}
                </Col>

                {mainState.empRequestRegularize &&
                  mainState.regularizeReasonList.map((row, idx) => {
                    return (
                      <Form.Group key={idx}>
                        <Row style={{ padding: "0 10px" }}>
                          <Col sm={12} className="flex-form mb-3">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "10px",
                              }}
                            >
                              <Form.Control
                                name="reasonName"
                                value={row.reasonName}
                                onChange={(e) => {
                                  handleRegularizePermission(e, idx);
                                }}
                                placeholder="Reason for regularize"
                                required
                              />
                              {idx == 0 && (
                                <FaRegSquarePlus
                                  onClick={addRegularizePermissionReason}
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "skyblue",
                                  }}
                                />
                              )}
                              {idx != 0 && (
                                <FiMinusSquare
                                  onClick={() =>
                                    removeRegularizePermissionReason(idx)
                                  }
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Form.Control.Feedback type={"invalid"}>
                          Reason for permission is required.
                        </Form.Control.Feedback>
                      </Form.Group>
                    );
                  })}

                <Col
                  sm="12"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                  className="mb-3"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <label style={{ textWrap: "nowrap" }}>
                      Specify the maximum number of regularization requests
                      allowed within the selected period &nbsp;
                    </label>
                    <Form.Control
                      style={{ width: "100%" }}
                      placeholder="Enter here"
                      name="regularizePeriodMax"
                      value={mainState.regularizePeriodMax}
                      onChange={(e) => {
                        mainHandleChange(e);
                      }}
                    />
                  </div>
                  {/* <span
                    style={{
                      position: "relative",
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Specify the maximum number of regularization requests
                    allowed within the selected period.
                  </span> */}
                </Col>

                <Col
                  sm="12"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                  className="mb-3"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <label style={{ textWrap: "nowrap" }}>
                      Apply Regularisation Within &nbsp;
                    </label>
                    <Form.Control
                      style={{ width: "100%" }}
                      placeholder="Enter here"
                      name="regularizeAttendDays"
                      value={mainState.regularizeAttendDays}
                      onChange={(e) => {
                        mainHandleChange(e);
                      }}
                    />
                    <label style={{ textWrap: "nowrap" }}>Days &nbsp;</label>
                  </div>
                  {/* <span
                    style={{
                      position: "relative",
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Specify the maximum number of regularization requests
                    allowed within the selected period.
                  </span> */}
                  {/* </Col>

                <Col
                  sm={12}
                  className="mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                > */}

                  <br />
                  <center>OR</center>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <label>
                      Set a deadline for submitting regularization requests
                      after an attendance discrepancy is identified. &nbsp;
                    </label>
                    &nbsp;&nbsp;
                    <Form.Select
                      name="regularizeAttendDate"
                      // value={mainState.regularizeAttendDate}
                      onChange={(e) => {
                        mainHandleChange(e);
                      }}
                      style={{ width: "170px" }}
                    >
                      <option value="" key={0}>
                        Select Date
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "1"
                            ? "selected"
                            : false
                        }
                        value="1"
                        key={1}
                      >
                        1
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "2"
                            ? "selected"
                            : false
                        }
                        value="2"
                        key={2}
                      >
                        2
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "3"
                            ? "selected"
                            : false
                        }
                        value="3"
                        key={3}
                      >
                        3
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "4"
                            ? "selected"
                            : false
                        }
                        value="4"
                        key={4}
                      >
                        4
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "5"
                            ? "selected"
                            : false
                        }
                        value="5"
                        key={5}
                      >
                        5
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "6"
                            ? "selected"
                            : false
                        }
                        value="6"
                        key={6}
                      >
                        6
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "7"
                            ? "selected"
                            : false
                        }
                        value="7"
                        key={7}
                      >
                        7
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "8"
                            ? "selected"
                            : false
                        }
                        value="8"
                        key={8}
                      >
                        8
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "9"
                            ? "selected"
                            : false
                        }
                        value="9"
                        key={9}
                      >
                        9
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "10"
                            ? "selected"
                            : false
                        }
                        value="10"
                        key={10}
                      >
                        10
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "11"
                            ? "selected"
                            : false
                        }
                        value="11"
                        key={11}
                      >
                        11
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "12"
                            ? "selected"
                            : false
                        }
                        value="12"
                        key={12}
                      >
                        12
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "13"
                            ? "selected"
                            : false
                        }
                        value="13"
                        key={13}
                      >
                        13
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "14"
                            ? "selected"
                            : false
                        }
                        value="14"
                        key={14}
                      >
                        14
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "15"
                            ? "selected"
                            : false
                        }
                        value="15"
                        key={15}
                      >
                        15
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "16"
                            ? "selected"
                            : false
                        }
                        value="16"
                        key={16}
                      >
                        16
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "17"
                            ? "selected"
                            : false
                        }
                        value="17"
                        key={17}
                      >
                        17
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "18"
                            ? "selected"
                            : false
                        }
                        value="18"
                        key={18}
                      >
                        18
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "19"
                            ? "selected"
                            : false
                        }
                        value="19"
                        key={19}
                      >
                        19
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "20"
                            ? "selected"
                            : false
                        }
                        value="20"
                        key={20}
                      >
                        20
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "21"
                            ? "selected"
                            : false
                        }
                        value="21"
                        key={21}
                      >
                        21
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "22"
                            ? "selected"
                            : false
                        }
                        value="22"
                        key={22}
                      >
                        22
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "23"
                            ? "selected"
                            : false
                        }
                        value="23"
                        key={23}
                      >
                        23
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "24"
                            ? "selected"
                            : false
                        }
                        value="24"
                        key={24}
                      >
                        24
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "25"
                            ? "selected"
                            : false
                        }
                        value="25"
                        key={25}
                      >
                        25
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "26"
                            ? "selected"
                            : false
                        }
                        value="26"
                        key={26}
                      >
                        26
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "27"
                            ? "selected"
                            : false
                        }
                        value="27"
                        key={27}
                      >
                        27
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "28"
                            ? "selected"
                            : false
                        }
                        value="28"
                        key={28}
                      >
                        28
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "29"
                            ? "selected"
                            : false
                        }
                        value="29"
                        key={29}
                      >
                        29
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "30"
                            ? "selected"
                            : false
                        }
                        value="30"
                        key={30}
                      >
                        30
                      </option>
                      <option
                        selected={
                          mainState.regularizeAttendDate == "31"
                            ? "selected"
                            : false
                        }
                        value="31"
                        key={31}
                      >
                        31 {mainState.regularizeAttendDate}
                      </option>
                    </Form.Select>
                  </div>

                  {/* <span
                    style={{
                      position: "relative",
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Set a deadline for submitting regularization requests after
                    an attendance discrepancy is identified.
                  </span> */}
                </Col>

                <Col
                  sm={12}
                  className="mb-3"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "7px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "10px",
                    }}
                  >
                    <label>
                      Select the order of leave deduction if regularisation
                      requests exceed the allowed limit &nbsp;
                    </label>
                    &nbsp;&nbsp;
                    <Form.Select
                      name="exceedRegularizeRequest"
                      value={mainState.exceedRegularizeRequest}
                      onChange={(e) => {
                        mainHandleChange(e);
                      }}
                      style={{ width: "270px" }}
                    >
                      <option value="" key={0}>
                        Select an option
                      </option>
                      <option value="1" key={1}>
                        Require Special Permission
                      </option>
                      <option value="2" key={2}>
                        Mark as Leave Without Pay (LOP)
                      </option>
                      <option value="3" key={3}>
                        Deduct from Leave Balance
                      </option>
                    </Form.Select>
                  </div>

                  {/* <span
                    style={{
                      position: "relative",
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "gray",
                    }}
                  >
                    Select the order of leave deduction if regularisation
                    requests exceed the allowed limit
                  </span> */}
                </Col>

                {mainState.exceedRegularizeRequest == 3 && (
                  <Col
                    sm={12}
                    className="mb-3"
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "7px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "10px",
                        border: mainState.moduleError && "1px solid red",
                      }}
                    >
                      <label style={{ textWrap: "nowrap" }}>
                        Action for Exceeding Regularisation Requests &nbsp;
                      </label>
                      &nbsp;&nbsp;

                      <MultiSelect
                        value={selectedLeaves}
                        labelledBy="Select Leaves"
                        options={leaveList}
                        onChange={(newValue) => {
                          setSelectedLeaves(newValue);
                          handleLeavesChange(newValue);
                        }}
                        style={{ width: '250px' }}
                        className="custom-multi-select"
                      />
                    </div>

                    <span
                      style={{
                        position: "relative",
                        marginTop: "10px",
                        fontSize: "14px",
                        color: "gray",
                      }}
                    >
                      {/* Select the order of leave deduction if regularisation requests exceed the allowed limit */}
                    </span>
                  </Col>
                )}
              </div>
            )}
          </Row>
          <Form.Control.Feedback type={"invalid"}>
            Field is required
          </Form.Control.Feedback>
        </Form.Group>

        {/* <Form.Group>
          <Row>
            <Col sm={12} className="flex-form mb-3">
              <label>Employees Can regularise attendance upto &nbsp;</label>
              <Form.Control
                name="reqularizeUpto"
                value={mainState.reqularizeUpto}
                onChange={(e) => {
                  mainHandleChange(e);
                }}
                style={{ width: "200px" }}
                placeholder="Enter days"
              />
            </Col>
          </Row>
        </Form.Group> */}

        <Row>
          <Col
            sm="12"
            style={{
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomButton
              type={"button"}
              class_name="p_btn"
              text={"Back"}
              url_or_onclick={() => setMainTabIndex(0)}
              icon={null}
              width="150px"
            />

            <CustomButton
              type={"button"}
              class_name="p_btn"
              text={"Next"}
              url_or_onclick={() => setMainTabIndex(2)}
              icon={null}
              width="150px"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const LateCheckInEarlyCheckOut = ({
  attendOptions,
  mainHandleChange,
  mainState,
  setMainTabIndex,
  setMainState,
}) => {
  const addPermissionReason = () => {
    const newArr = [...mainState.permissionReasonList];
    newArr.push({ reasonName: "" });
    setMainState({ ...mainState, permissionReasonList: newArr });
  };
  const removePermissionReason = (index) => {
    const newArr = [...mainState.permissionReasonList];
    newArr.splice(index, 1);
    setMainState({ ...mainState, permissionReasonList: newArr });
  };

  const handlePermission = (e, index) => {
    const {
      target: { name, value },
    } = e;
    const newArr = [...mainState.permissionReasonList];
    newArr[index][name] = value;
    setMainState({ ...mainState, permissionReasonList: newArr });
  };
  // Late reason ==============================
  const [lateReason, setLateReason] = useState([{ reasonName: "" }]);

  const addLateReason = () => {
    const newArr = [...mainState.lateReasonList];
    newArr.push({ reasonName: "" });
    setMainState({ ...mainState, lateReasonList: newArr });
  };
  const removeLateReason = (index) => {
    const newArr = [...mainState.lateReasonList];
    newArr.splice(index, 1);
    setMainState({ ...mainState, lateReasonList: newArr });
  };

  const handleLateReason = (e, index) => {
    const {
      target: { name, value },
    } = e;
    const newArr = [...mainState.lateReasonList];
    newArr[index][name] = value;
    setMainState({ ...mainState, lateReasonList: newArr });
  };

  // Early reason ==============================
  const [earlyReason, setEarlyReason] = useState([{ reasonName: "" }]);

  const addEarlyReason = () => {
    const newArr = [...mainState.earlyCheckOutReasonList];
    newArr.push({ reasonName: "" });
    setMainState({ ...mainState, earlyCheckOutReasonList: newArr });
  };
  const removeEarlyReason = (index) => {
    const newArr = [...mainState.earlyCheckOutReasonList];
    newArr.splice(index, 1);
    setMainState({ ...mainState, earlyCheckOutReasonList: newArr });
  };

  const handleEarlyReason = (e, index) => {
    const {
      target: { name, value },
    } = e;
    const newArr = [...mainState.earlyCheckOutReasonList];
    newArr[index][name] = value;
    setMainState({ ...mainState, earlyCheckOutReasonList: newArr });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Permissions can be availed for &nbsp;</label>
                <Form.Control
                  name="permissionAvailDays"
                  value={mainState.permissionAvailDays}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;days in a&nbsp;&nbsp;</label>
                <Form.Select
                  name="permissionAvailYears"
                  value={mainState.permissionAvailYears}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  style={{ width: "170px" }}
                >
                  <option value="">Select an option</option>
                  {attendOptions.map((row, idx) => {
                    return (
                      <option value={row.id} key={idx}>
                        {row.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          {mainState.permissionAvailDays != "" &&
            mainState.permissionAvailYears != "" &&
            mainState.permissionReasonList.map((row, idx) => {
              return (
                <Form.Group key={idx}>
                  <Row>
                    <Col sm={12} className="flex-form mb-3">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Form.Control
                          name="reasonName"
                          value={row.reasonName}
                          onChange={(e) => {
                            handlePermission(e, idx);
                          }}
                          placeholder="Reason for permission"
                          required
                        />
                        {idx == 0 && (
                          <FaRegSquarePlus
                            onClick={addPermissionReason}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "skyblue",
                            }}
                          />
                        )}
                        {idx != 0 && (
                          <FiMinusSquare
                            onClick={() => removePermissionReason(idx)}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type={"invalid"}>
                    Reason for permission is required.
                  </Form.Control.Feedback>
                </Form.Group>
              );
            })}
          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Late Arrival of &nbsp;</label>
                <Form.Control
                  name="lateArrivalMinutes"
                  value={mainState.lateArrivalMinutes}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  style={{ width: "200px" }}
                  placeholder="Enter minutes"
                />
                <label>&nbsp; Minutes is allowed per request</label>
              </Col>
            </Row>
          </Form.Group>

          {mainState.lateArrivalMinutes != "" &&
            mainState.lateReasonList.map((row, idx) => {
              return (
                <Form.Group key={idx}>
                  <Row>
                    <Col sm={12} className="flex-form mb-3">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Form.Control
                          name="reasonName"
                          value={row.reasonName}
                          onChange={(e) => {
                            handleLateReason(e, idx);
                          }}
                          placeholder="Reason for Late"
                          required
                        />
                        {idx == 0 && (
                          <FaRegSquarePlus
                            onClick={addLateReason}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "skyblue",
                            }}
                          />
                        )}
                        {idx != 0 && (
                          <FiMinusSquare
                            onClick={() => removeLateReason(idx)}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type={"invalid"}>
                    Reason for permission is required.
                  </Form.Control.Feedback>
                </Form.Group>
              );
            })}

          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Early Check Out of &nbsp;</label>
                <Form.Control
                  name="earlyCheckoutMinutes"
                  value={mainState.earlyCheckoutMinutes}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  style={{ width: "200px" }}
                  placeholder="Enter minutes"
                />
                <label>&nbsp; Minutes is allowed per request</label>
              </Col>
            </Row>
          </Form.Group>

          {mainState.earlyCheckoutMinutes != "" &&
            mainState.earlyCheckOutReasonList.map((row, idx) => {
              return (
                <Form.Group key={idx}>
                  <Row>
                    <Col sm={12} className="flex-form mb-3">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Form.Control
                          name="reasonName"
                          value={row.reasonName}
                          onChange={(e) => {
                            handleEarlyReason(e, idx);
                          }}
                          placeholder="Reason for Early"
                          required
                        />
                        {idx == 0 && (
                          <FaRegSquarePlus
                            onClick={addEarlyReason}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "skyblue",
                            }}
                          />
                        )}
                        {idx != 0 && (
                          <FiMinusSquare
                            onClick={() => removeEarlyReason(idx)}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type={"invalid"}>
                    Reason for permission is required.
                  </Form.Control.Feedback>
                </Form.Group>
              );
            })}

          <Row>
            <Col
              sm="12"
              style={{
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CustomButton
                type={"button"}
                class_name="p_btn"
                text={"Back"}
                url_or_onclick={() => setMainTabIndex(1)}
                icon={null}
                width="150px"
              />

              <CustomButton
                type={"button"}
                class_name="p_btn"
                text={"Next"}
                url_or_onclick={() => setMainTabIndex(3)}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const ApprovalChain = ({
  attendOptions,
  mainHandleChange,
  setMainTabIndex,
  mainState,
  setMainState,
}) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const commonStore = useSelector((state) => state.common);
  const empStore = useSelector((state) => state.employee);

  const [staffList, setStaffList] = useState([]);

  const getStaffList = useCallback(() => {
    const path = `admin/getEmployeeManageList?userId=${user.user_id}`;
    dispatch(getEmployeeData(path));
  }, []);

  useEffect(() => {
    getStaffList();
  }, []);

  useEffect(() => {
    if (empStore.empGetSuccess) {
      console.log(empStore.empGetData);
      setStaffList(empStore.empGetData);
      dispatch(initEmpState());
    }
  }, [empStore.empGetSuccess]);

  const addMoreAssignees = () => {
    const newAssignees = [...mainState.approvalSettings];
    newAssignees.push({
      staffId: "",
      assigneeName: "",
      roleId: "",
      isSkipStep: false,
      skipStep: 0,
    });
    setMainState({ ...mainState, approvalSettings: newAssignees });
  };

  const removeLevel = (id) => {
    const newAssignees = [...mainState.approvalSettings];

    newAssignees.splice(id, 1);
    setMainState({ ...mainState, approvalSettings: newAssignees });
  };

  const handleApprovalChange = (e, idx) => {
    const {
      target: { value },
    } = e;
    const data = JSON.parse(value);
    const newAssignees = [...mainState.approvalSettings];
    newAssignees[idx].staffId = data.staffId;
    newAssignees[idx].assigneeName = data.full_name;
    newAssignees[idx].roleId = data.emp_role;
    setMainState({ ...mainState, approvalSettings: newAssignees });
  };

  const handleSkipCheckChange = (e, idx) => {
    const {
      target: { checked },
    } = e;
    const newAssignees = [...mainState.approvalSettings];
    newAssignees[idx].isSkipStep = checked ? true : false;
    newAssignees[idx].skipStep = 0;
    setMainState({ ...mainState, approvalSettings: newAssignees });
  };
  const handleCheckChange = (e, idx) => {
    const {
      target: { value },
    } = e;
    const newAssignees = [...mainState.approvalSettings];
    newAssignees[idx].skipStep = value;
    setMainState({ ...mainState, approvalSettings: newAssignees });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div
        className="more-setting-form-container"
        style={{ position: "relative", height: "auto", paddingBottom: "40px" }}
      >
        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Mandatory Approval required if request exceeds</label>
                <Form.Control
                  name="mandatoryApprovalRequest"
                  value={mainState.mandatoryApprovalRequest}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;times in a&nbsp;</label>
                <Form.Select
                  name="mandatoryApprovalYears"
                  value={mainState.mandatoryApprovalYears}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  style={{ width: "170px" }}
                >
                  <option value="">Select an option</option>
                  {attendOptions.map((row, idx) => {
                    return (
                      <option value={row.id} key={idx}>
                        {row.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Row className="flex-form mb-5">
              {mainState.approvalSettings.map((row, idx) => {
                return (
                  <Col key={idx} sm={12} className="p-0 mb-3 approval-con">
                    <Card style={{ width: "100%" }}>
                      <Card.Header
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Level {idx + 1}
                          <b> Approval</b>
                        </span>
                        {idx != 0 && (
                          <RiDeleteBin6Line
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => removeLevel(idx)}
                          />
                        )}
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <Form.Label>Assignees</Form.Label>
                          <Form.Select
                            placeholder="Role / Employee"
                            onChange={(e) => {
                              handleApprovalChange(e, idx);
                            }}
                          >
                            <option value="">Role / Employee</option>
                            {staffList?.map((row1, idx) => {
                              return (
                                <option
                                  value={JSON.stringify({
                                    staffId: row1.staffId,
                                    emp_role: row1.emp_role,
                                    full_name: row1.full_name,
                                  })}
                                  key={idx}
                                  selected={
                                    row1.staffId == row.staffId ? true : false
                                  }
                                >
                                  {row1.full_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              paddingTop: "20px",
                            }}
                          >
                            <Form.Check
                              label="Skip step if no action taken in"
                              onChange={(e) => handleSkipCheckChange(e, idx)}
                              checked={row.skipStep == "0" ? false : true}
                            />

                            <InputGroup
                              style={{
                                width: "110px",
                              }}
                            >
                              <Form.Control
                                placeholder="10"
                                onChange={(e) => handleCheckChange(e, idx)}
                                readOnly={row.isSkipStep ? false : true}
                                value={row.skipStep}
                              />
                              <InputGroup.Text id="basic-addon1">
                                days
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {/* <div className="ver-line"></div> */}
                  </Col>
                );
              })}

              <Col sm="5" className="p-0 mt-2">
                <Button
                  variant="outline-primary"
                  style={{ width: "150px", marginTop: "-16px" }}
                  onClick={addMoreAssignees}
                >
                  Add More Level
                </Button>
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <Col
              sm="12"
              style={{
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CustomButton
                type={"button"}
                class_name="p_btn"
                text={"Back"}
                url_or_onclick={() => setMainTabIndex(2)}
                icon={null}
                width="150px"
              />

              <CustomButton
                type={"button"}
                class_name="p_btn"
                text={"Next"}
                url_or_onclick={() => setMainTabIndex(4)}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const AutoHalfDay = ({
  mainHandleChange,
  setMainTabIndex,
  mainState,
  beforeSubmitSettingData,
  store,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    beforeSubmitSettingData();
    if (mainState.moduleName == "") {
      setMainTabIndex(0);
    }
  };
  return (
    <>
      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <div className="form">
          {" "}
          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Auto Half Day if Late by&nbsp;</label>
                <Form.Control
                  name="allowHalfDays"
                  value={mainState.allowHalfDays}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;Hours&nbsp;&nbsp;</label>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>
          {/* ---------------- */}
          <Form.Group>
            <Row>
              <Col sm={12} className="flex-form mb-3">
                <label>Allow upto &nbsp;</label>
                <Form.Control
                  name="allowHalfDaysYears"
                  value={mainState.allowHalfDaysYears}
                  onChange={(e) => {
                    mainHandleChange(e);
                  }}
                  style={{ width: "200px" }}
                  placeholder="Enter days"
                />
                <label>&nbsp;&nbsp;days&nbsp;&nbsp;</label>
              </Col>
            </Row>
          </Form.Group>
          {/* ------------------------ */}
          {/* ------------------------ */}
          <Form.Group style={{ padding: "10px 0" }}>
            <Row>
              <Col
                sm="12"
                style={{
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CustomButton
                  type={"Button"}
                  class_name="p_btn"
                  text={"Back"}
                  url_or_onclick={() => setMainTabIndex(3)}
                  icon={null}
                  width="150px"
                />

                <CustomButton
                  type={"submit"}
                  class_name="p_btn"
                  text={store.mSetLoading ? "Submitting..." : "Submit"}
                  url_or_onclick={handleSubmit}
                  icon={null}
                  width="150px"
                />
              </Col>
            </Row>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default UpdateSettings;
