// import React, { useState, useCallback, useEffect, useRef } from "react";
// import {
//   GoogleMap,
//   DirectionsService,
//   DirectionsRenderer,
//   Marker,
//   InfoWindow,
//   useJsApiLoader,
// } from "@react-google-maps/api";
// import MarkerIcon from "../../assets/icons/marker.png";

// const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// const MAX_WAYPOINTS = 25; // The maximum number of waypoints allowed by Google Directions API

// const MapWithDynamicPointNames = ({
//   center,
//   origin,
//   destination,
//   waypoints,
// }) => {
//   const [directionsResponses, setDirectionsResponses] = useState([]);
//   const [selectedPoint, setSelectedPoint] = useState(null); // For tooltip display
//   const [pointNames, setPointNames] = useState({}); // Store names dynamically
//   const mapInstanceRef = useRef(null);
//   const geocoderRef = useRef(null);

//   const mapContainerStyle = {
//     width: "100%",
//     height: "500px",
//   };

//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: API_KEY,
//   });

//   const onMapLoad = useCallback((map) => {
//     mapInstanceRef.current = map;
//     geocoderRef.current = new window.google.maps.Geocoder(); // Initialize Geocoder
//   }, []);

//   // Helper to split waypoints into chunks of MAX_WAYPOINTS each
//   const splitWaypoints = (allWaypoints, maxWaypoints = MAX_WAYPOINTS) => {
//     const chunks = [];
//     for (let i = 0; i < allWaypoints.length; i += maxWaypoints) {
//       chunks.push(allWaypoints.slice(i, i + maxWaypoints));
//     }
//     return chunks;
//   };

//   // Fetch point name using geocoder
//   const fetchPointName = (latLng, key) => {
//     if (!geocoderRef.current) return;

//     geocoderRef.current.geocode({ location: latLng }, (results, status) => {
//       if (status === "OK" && results[0]) {
//         setPointNames((prev) => ({
//           ...prev,
//           [key]: results[0].formatted_address,
//         }));
//       } else {
//         console.error("Geocoder failed due to:", status);
//       }
//     });
//   };

//   useEffect(() => {
//     // Fetch names for all points (origin, waypoints, destination)
//     fetchPointName(origin, "origin");
//     fetchPointName(destination, "destination");

//     waypoints.forEach((waypoint, index) => {
//       fetchPointName(waypoint.location, `waypoint-${index}`);
//     });
//   }, [origin, destination, waypoints]);

//   const handleDirectionsCallback = useCallback((response) => {
//     if (response !== null && response.status === "OK") {
//       setDirectionsResponses((prev) => [...prev, response]);
//     } else {
//       console.error("Error fetching directions:", response);
//     }
//   }, []);

//   useEffect(() => {
//     if (isLoaded && (origin || destination || waypoints)) {
//       setDirectionsResponses([]); // Reset directionsResponses when new data comes in

//       const waypointChunks = splitWaypoints(waypoints); // Split the waypoints into chunks

//       waypointChunks.forEach((chunk, index) => {
//         const chunkOrigin = index === 0 ? origin : chunk[0].location;
//         const chunkDestination =
//           index === waypointChunks.length - 1
//             ? destination
//             : chunk[chunk.length - 1].location;

//         const request = {
//           origin: chunkOrigin,
//           destination: chunkDestination,
//           travelMode: "DRIVING",
//           waypoints: chunk.map((wp) => ({
//             location: wp.location,
//             stopover: true,
//           })),
//         };

//         // Call DirectionsService for each chunk of waypoints
//         const service = new window.google.maps.DirectionsService();
//         service.route(request, handleDirectionsCallback);
//       });
//     }
//   }, [isLoaded, origin, destination, waypoints]);

//   const handleMouseOver = (marker) => {
//     setSelectedPoint(marker);
//   };

//   const handleMouseOut = () => {
//     setSelectedPoint(null);
//   };

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={mapContainerStyle}
//       center={center}
//       zoom={15} // Set your desired zoom level here
//       onLoad={onMapLoad}
//       options={{
//         draggable: true,
//         scrollwheel: true,
//         disableDefaultUI: true,
//         zoomControl: true, // Explicitly enable zoom control
//         fullscreenControl: true, // Explicitly enable fullscreen control
//       }}
//     >
//       {/* Render all DirectionsRenderer */}
//       {directionsResponses.map((response, index) => (
//         <DirectionsRenderer
//           key={`directions-${index}`}
//           options={{
//             directions: response,
//             preserveViewport: true, // Prevent auto zoom from directions
//             suppressMarkers: true,
//             polylineOptions: {
//               strokeColor: "#2ecc71", // Custom route color (red)
//               strokeWeight: 5, // Adjust the thickness of the route line
//               strokeOpacity: 0.7, // Adjust opacity of the route line
//             },
//           }}

//         />
//       ))}

//       {/* Markers for origin, destination, and waypoints */}
//       <Marker
//         key="origin"
//         position={origin}
//         options={{
//           icon: {
//             url: MarkerIcon,
//             scaledSize: new window.google.maps.Size(24, 24),
//           },
//         }}
//         draggable
//         onMouseOver={() => handleMouseOver(origin)}
//         onMouseOut={handleMouseOut}
//       />
//       <Marker
//         key="destination"
//         position={destination}
//         options={{
//           icon: {
//             url: MarkerIcon,
//             scaledSize: new window.google.maps.Size(24, 24),
//           },
//         }}
//         draggable
//         onMouseOver={() => handleMouseOver(destination)}
//         onMouseOut={handleMouseOut}
//       />

//       {/* Render markers for waypoints */}
//       {/* {waypoints.map((waypoint, index) => (
//         <Marker
//           key={`waypoint-${index}`}
//           position={waypoint.location}
//           options={{
//             icon: {
//               url: MarkerIcon,
//               scaledSize: new window.google.maps.Size(24, 24),
//             },
//           }}
//           draggable
//           onMouseOver={() => handleMouseOver(waypoint.location)}
//           onMouseOut={handleMouseOut}
//         />
//       ))} */}

//       {/* Tooltip for selected marker */}
//       {selectedPoint && (
//         <InfoWindow
//           position={selectedPoint}
//           options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
//         >
//           <div
//             style={{
//               padding: "10px",
//               paddingRight: "30px",
//               maxWidth: "200px",
//               borderRadius: "10px",
//               backgroundColor: "#ffffff",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//               fontFamily: "Arial, sans-serif",
//             }}
//           >
//             <p
//               style={{
//                 margin: "0",
//                 fontSize: "14px",
//                 color: "#555",
//                 textAlign: "center",
//               }}
//             >
//               {pointNames[selectedPoint.key] || "Loading..."}
//             </p>
//           </div>
//         </InfoWindow>
//       )}
//     </GoogleMap>
//   ) : (
//     <div>Loading...</div>
//   );
// };

// export default MapWithDynamicPointNames;






import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import MarkerIcon from "../../assets/icons/marker.png";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const MAX_WAYPOINTS = 23;

const MapWithDynamicPointNames = ({
  center,
  origin,
  destination,
  waypoints,
}) => {
  const [directionsResponses, setDirectionsResponses] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [pointNames, setPointNames] = useState({});
  const mapInstanceRef = useRef(null);
  const geocoderRef = useRef(null);

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const onMapLoad = useCallback((map) => {
    mapInstanceRef.current = map;
    geocoderRef.current = new window.google.maps.Geocoder();
  }, []);

  // Helper to split waypoints into batches
  const splitWaypoints = (allWaypoints, maxWaypoints = MAX_WAYPOINTS) => {
    const chunks = [];
    for (let i = 0; i < allWaypoints.length; i += maxWaypoints) {
      chunks.push(allWaypoints.slice(i, i + maxWaypoints));
    }
    return chunks;
  };

  // Fetch directions for each chunk of waypoints
  useEffect(() => {
    if (!isLoaded) return;

    const waypointChunks = splitWaypoints(waypoints);
    const requests = waypointChunks.map((chunk, index) => {
      const chunkOrigin = index === 0 ? origin : chunk[0].location;
      const chunkDestination =
        index === waypointChunks.length - 1
          ? destination
          : chunk[chunk.length - 1].location;
      const chunkWaypoints =
        index === 0
          ? chunk.slice(0, chunk.length - 1)
          : chunk.slice(1, chunk.length - 1);

      return {
        origin: chunkOrigin,
        destination: chunkDestination,
        travelMode: "DRIVING",
        waypoints: chunkWaypoints,
      };
    });

    // Fetch all route directions
    const service = new window.google.maps.DirectionsService();
    const fetchDirectionsPromises = requests.map(
      (request) =>
        new Promise((resolve, reject) => {
          service.route(request, (response, status) => {
            if (status === "OK") {
              resolve(response);
            } else {
              reject(`Directions request failed with status: ${status}`);
            }
          });
        })
    );

    Promise.all(fetchDirectionsPromises)
      .then((responses) => {
        setDirectionsResponses(responses);
      })
      .catch((error) => console.error(error));
  }, [isLoaded, origin, destination, waypoints]);

  // Fetch point names dynamically
  const fetchPointName = (latLng, key) => {
    if (!geocoderRef.current) return;

    geocoderRef.current.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setPointNames((prev) => ({
          ...prev,
          [key]: results[0].formatted_address,
        }));
      } else {
        console.error("Geocoder failed due to:", status);
      }
    });
  };

  useEffect(() => {
    fetchPointName(origin, "origin");
    waypoints.forEach((point, index) =>
      fetchPointName(point.location, `waypoint-${index}`)
    );
    fetchPointName(destination, "destination");
  }, [origin, waypoints, destination]);

  const handleMouseOver = (marker) => {
    setSelectedPoint(marker);
  };

  const handleMouseOut = () => {
    setSelectedPoint(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={15}
      onLoad={onMapLoad}
      options={{
        draggable: true,
        scrollwheel: true,
        disableDefaultUI: true,
      }}
    >
      {/* Render directions */}
      {directionsResponses.map((response, index) => (
        <DirectionsRenderer
          key={`directions-${index}`}
          options={{
            directions: response,
            preserveViewport: true, // Prevent auto zoom from directions
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#2ecc71", // Custom route color (red)
              strokeWeight: 5, // Adjust the thickness of the route line
              strokeOpacity: 0.7, // Adjust opacity of the route line
            },
          }}
        />
      ))}

      <Marker
        key={"key1"}
        position={origin}
        icon={{
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(24, 24),
        }}
        draggable
        onMouseOver={() => handleMouseOver(origin)}
        onMouseOut={handleMouseOut}
      />

      <Marker
        key={"key2"}
        position={destination}
        icon={{
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(24, 24),
        }}
        draggable
        onMouseOver={() => handleMouseOver(destination)}
        onMouseOut={handleMouseOut}
      />

      {/* Markers for waypoints */}
      {/* {[origin, ...waypoints.map((w) => w.location), destination].map(
        (point, index) => {
          const key =
            index === 0
              ? "origin"
              : index === waypoints.length + 1
                ? "destination"
                : `waypoint-${index - 1}`;

          return (
            <Marker
              key={key}
              position={point}
              icon={{
                url: MarkerIcon,
                scaledSize: new window.google.maps.Size(24, 24),
              }}
              draggable
              onMouseOver={() => handleMouseOver(point)}
              onMouseOut={handleMouseOut}
            />
          );
        }
      )} */}

      {/* Tooltip for selected marker */}
      {selectedPoint && (
        <InfoWindow
          position={{
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          }}
          options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
        >
          <div>
            <p>{pointNames[selectedPoint.key] || "Loading..."}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default MapWithDynamicPointNames;
