import "./Designation.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import AddIcon from "@mui/icons-material/Add";
import { Form, Row, Col } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import ButtonCom from "../../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchDesignationList,
  fetchModuleData,
  saveDesignation,
  initState,
} from "../../../redux/slices/designationSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";

import { scrollBottom } from "../../../services/commonServices";

const animatedComponents = makeAnimated();

const Designation = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const dispatch = useDispatch();
  const state = useSelector((state) => state.designation);
  const commonState = useSelector((state) => state.common);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectError, setSelectError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [designationList, setDesignationList] = useState([]);
  const [moduleList, setModuleList] = useState([]);

  const fetchList = useCallback(() => {
    dispatch(fetchDesignationList());
    dispatch(fetchModuleData());
  }, []);

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (state.desGetSuccess) {
      const newArr = state.desGetData.map((v) => {
        return { value: v.moduleId, label: v.module_name };
      });
      setModuleList(newArr);
    }
    if (state.desGetError) {
    }
  }, [state.desGetSuccess, state.desGetError]);

  useEffect(() => {
    if (state.desigSuccess) {
      setDesignationList(state.desigData);
    }
  }, [state.desigSuccess, state.desigError]);

  const [saveData, setSAveData] = useState({
    designationId: null,
    designationName: "",
    modules: [],
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSAveData({
      ...saveData,
      designationName: "",
      modules: [],
    });
    setValidated(false);
    setSelectError(false);
    setOpen(false);
  };

  const inputHandleChange = (e) => {
    const {
      target: { value },
    } = e;
    setSAveData({
      ...saveData,
      designationName: value,
    });
  };

  const handleChange = (event) => {
    setSAveData({
      ...saveData,
      modules: event,
    });
    setSelectError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (saveData.modules.length < 1) {
      setSelectError(true);
    }
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const module = saveData.modules.map((v) => v.value).toString();
      let data = {
        userId: 3,
        designationName: saveData.designationName,
        modules: module,
      };
      dispatch(saveDesignation(data));
      setSAveData({
        ...saveData,
        designationName: "",
        modules: [],
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (state.desSuccess) {
      toast.success(state.desMessage)
      dispatch(fetchDesignationList());
      dispatch(initState());
    }
    if (state.desError) {
      toast.error(state.desMessage)
      dispatch(fetchDesignationList());
      dispatch(initState());
    }
  }, [state.desSuccess, state.desError]);

  // DELETE UPDATE RECORD FUNCTION ===================
  const [show, setShow] = useState(false);

  const [savedModuleList, setSavedModuleList] = useState([]);
  console.log(savedModuleList);
  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = (row) => {
    const savedModules = row.modules.split(",");
    const brnIndex = savedModules.map((v) => {
      return moduleList.findIndex((obj) => {
        return parseInt(obj.value) === parseInt(v);
      });
    });
    setSavedModuleList(brnIndex)
    setIsEdit(true);
    setSAveData({
      ...saveData,
      designationId: row.designationId,
      designationName: row.designation_name,
      modules: [],
    });
    handleClickOpen();
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateDepartment",
      data: saveData,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.departmentId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    // let d = {
    //   path: "admin/deleteDepartment",
    //   data: {
    //     userId: user.user_id,
    //     departmentId: delId,
    //   },
    // };
    // dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      fetchList();
      setDelId(null);
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      fetchList();
      setDelId(null);
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);
  // =================================================

  const heading = [
    {
      name: "Designation",
      label: "designation_name",
    },

    {
      name: "Modules",
      label: "modules",
    },

    {
      name: "Status",
      label: "status",
      render: (rowData) => {
        return rowData.status == 1 ? "Active" : "InActive";
      },
    },

    {
      name: "",
      label: "id",
      width: 300,
      render: (rowData) => {
        return (
          <div className="Department-table-button">
            <CiEdit
              style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
              onClick={() => {
                handleEdit(rowData);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(rowData)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Designation</p>
      </div>

      <div className="SettingBody-card mt-3">
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="">
            <div className="Departments-btn pb-5">
              <h1> Designation </h1>
              <ButtonCom
                type="button"
                class_name={"p_btn"}
                text="Add Designation"
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            <TableMui
              tableHead={heading}
              data={designationList}
              rowPagesPlay={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              Pages={10}
              editId={false}
            />
          </div>
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "16px", fontWeight: "bold" }}>
              Add New Designation
            </DialogTitle>
            <Form
              noValidate
              validated={validated}
              onSubmit={isEdit ? handleEditSubmit : handleSubmit}
            >
              <DialogContent style={{ overflow: "visible" }}>
                <Row>
                  <Col sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        type="text"
                        name="designationName"
                        value={saveData.designationName}
                        placeholder="Enter Designation Name"
                        onChange={inputHandleChange}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Please provide valid designation name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* ---------------------------- */}
                  <Col sm="12">
                    <Form.Group as={Col} controlId="my_multiselect_field">
                      <Form.Label>Select Modules</Form.Label>
                      <FormControl style={{ width: "100%" }} required>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={savedModuleList.map((r) => moduleList[r])}
                          // value={saveData.modules}
                          isMulti
                          options={moduleList}
                          onChange={handleChange}
                        />
                      </FormControl>
                      {selectError && (
                        <span style={{ color: "#dc4345", fontSize: "14px" }}>
                          Please provide valid module name.
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions style={{ paddingRight: "20px" }}>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={handleClose}
                  style={{
                    height: "35px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "30px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  style={{
                    height: "35px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "30px",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
          <div className="Branches-btn"></div>
        </div>
      </div>
    </div>
  );
};

export default Designation;
