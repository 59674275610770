const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const getAddressByLatLng = async (marker) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.lat},${marker.lng}&key=${API_KEY}`
      );
      const data = await response.json();
      if (data.status === "OK" && data.results[0]) {
        return data.results[0].formatted_address;
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
    return "";
  };

  export { getAddressByLatLng }

  // const center = {
  //   lat: 21.348480687703674,
  //   lng: 81.70718910059041,
  // };

  // const markers = [
  //   {
  //     id: 1,
  //     lat: 21.348480687703674,
  //     lng: 81.70718910059041,
  //     name: "Marker 1",
  //   },
  //   {
  //     id: 2,
  //     lat: 21.286980410083334,
  //     lng: 81.72022422964724,
  //     name: "Marker 2",
  //   },
  //   {
  //     id: 3,
  //     lat: 21.269739555952803,
  //     lng: 81.67135381306662,
  //     name: "Marker 3",
  //   },
  //   {
  //     id: 4,
  //     lat: 21.244142402184515,
  //     lng: 81.63496160183927,
  //     name: "Marker 4",
  //   },
  // ];