import {
  Cards,
  WishesCards,
  WishesCardsUserInfo,
} from "../../components/cards/Cards";
import "./Dashboard.css";
import Avatar from "@mui/material/Avatar";
import { BarChart } from "@mui/x-charts/BarChart";
import { useCallback, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  commonGetData,
  commonGetDataOne,
  initCommonState,
} from "../../redux/slices/commonSlice";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/button/button";
import { Card, Col, Row } from "react-bootstrap";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : []
  );

  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  // stat variable
  // const [userRecord, setUserRecord] = useState({});
  const [isAlert, setAlert] = useState(false);
  const [isBranchAlert, setBranchAlert] = useState(false);
  const [isDepartmentAlert, setDepartmentAlert] = useState(false);
  const [isEmployerIdAlert, setEmployerIdAlert] = useState(false);
  const [isShiftAlert, setShiftAlert] = useState(false);

  const getUserRecord = useCallback(() => {
    dispatch(commonGetData(`/admin/checkUserRecords?userId=${user.user_id}`));
  });

  useEffect(() => {
    getUserRecord();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      for (let key in commonState.commonGetData) {
        if (commonState.commonGetData.hasOwnProperty(key)) {
          if (
            key == "isBranchRecord" &&
            commonState.commonGetData[key] === false
          ) {
            setBranchAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isDepartmentRecord" &&
            commonState.commonGetData[key] === false
          ) {
            setDepartmentAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isEmployerId" &&
            commonState.commonGetData[key] === false
          ) {
            setEmployerIdAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isShift" &&
            commonState.commonGetData[key] === false
          ) {
            setShiftAlert(true);
            setAlert(true);
            break;
          }
        }
      }
      // setUserRecord(commonState.commonGetData);
      dispatch(initCommonState());
    }
    if (commonState.commonGetError) {
      dispatch(initCommonState());
    }
  }, [commonState.commonGetSuccess, commonState.commonGetError]);

  const path = `admin/getEmployeeStaffList?userId=${user.user_id}`;

  const [usersList, setUsersList] = useState([]);

  const getEmployeeList = useCallback(() => {
    dispatch(commonGetDataOne(path));
  }, []);

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      if (
        commonState.commonGetOneData == undefined ||
        commonState.commonGetOneData == ""
      ) {
        setUsersList([]);
        dispatch(initCommonState());
      } else {
        setUsersList(commonState.commonGetOneData);
        dispatch(initCommonState());
      }
    }
  }, [commonState.commonGetOneSuccess]);

  const goNotificationPage = () => {
    navigate("/notification");
  };

  return (
    <div className="Dashboard">
      <div className="Dashboard-head">
        <div className="Dashboard-info">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 70, height: 70 }}
          />
          <h1>{user.company_name}</h1>
        </div>
        <div className="Dashboard-card">
          {isAlert && (
            <div className="setting-notification">
              <div className="record-div">
                <div>
                  Records in your settings are not configured. To start, click
                  the link to complete.
                </div>

                {isBranchAlert && (
                  <Link to="/company_branches">Add Branch</Link>
                )}
                {isDepartmentAlert && (
                  <Link to="/company-departments">Add Department</Link>
                )}
                {isEmployerIdAlert && (
                  <Link to="/my-employee-id">Add Employer Id</Link>
                )}
                {isShiftAlert && (
                  <Link to="/attendance-settings-shifts">Add Shift</Link>
                )}
              </div>
            </div>
          )}
          <div className="Dashboard-card-items mb-5">
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Total Employees"}
              count={usersList.length}
              supCount={usersList.length}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Active Employees"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"New Joiners"}
              count={134}
              supCount={""}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"On Duty"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"On Leave"}
              count={134}
              supCount={134}
            />
          </div>
        </div>
      </div>
<br />
      <Row className="mt-5 p-5">
        <Col sm="8">
          <div className="Dashboard-bar-chart">
            <BarChart
              series={[
                { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
                { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
              ]}
              height={290}
              xAxis={[
                {
                  data: [
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                    "January",
                    "February",
                    "March",
                  ],
                  scaleType: "band",
                },
              ]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
          </div>
        </Col>
        <Col sm="4">
          <Card>
            <Card.Header>Overview</Card.Header>
            <Card.Body>
              <ul className="overview-list">
                <li>Employees Auto Clocked-Out Today: <span>0</span> </li>
                <li>Pending Regularisation Requests: <span>0</span> </li>
                <li>Overtime Policy Exceptions: <span>0</span> </li>
              </ul>
            </Card.Body>
          </Card>

          <Card className="mt-2">
            <Card.Header>Quick Link</Card.Header>
            <Card.Body>
              <ul className="overview-list">
                <li> <Link to="/attendance-setting">Manage Auto Clock-Out Settings <ArrowForwardIcon /></Link> </li>
                <li> <Link to="/notification">View Requests <ArrowForwardIcon /> </Link> </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>


   
{/*"Quick Links:
"Manage Auto Clock-Out Settings"
""
[9:11 pm, 9/12/2024] Sathish Part Time Job: hello
[9:27 pm, 9/12/2024] Sathish Part Time Job: 1- Approved, 2- Rejected 3-canceled */}


      {/* <div className="chart-btn">
        <div className="btn-container">
          <Row>
            <Col sm="6">
              
            </Col>
          </Row>
          <CustomButton
            type={"button"}
            text={"New Request"}
            class_name={"p_btn"}
            url_or_onclick={goNotificationPage}
            icon={null}
            width={""}
          />
        </div>
      </div> */}

      <div className="Dashboard-wishes-container">
        {/* Birthday Wishes */}
        <WishesCards title={"Birthday Wishes"}>
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"Name"}
          />
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"name"}
          />
        </WishesCards>

        {/* Work Anniversary */}
        <WishesCards title={"Work Anniversary"}>
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"Name"}
          />
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"name"}
          />
        </WishesCards>
      </div>
    </div>
  );
};

export default Dashboard;
