/* eslint-disable no-unused-expressions */
import "./style.css";
import { Col, Form, Row } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchData } from "../../redux/slices/branchSlice";
import { fetchDepartmentData } from "../../redux/slices/departmentSlice";
import {
  commonGetData,
  commonGetDataOne,
  commonGetDataTwo,
  initState,
  initCommonState,
} from "../../redux/slices/commonSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import MarkerMap from "../../components/Map/MarkerMap";
// import Map from "../../components/Map/Map";
import RoadMap from "../../components/Map/RoadMap";

const Index = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const dispatch = useDispatch();
  const branchState = useSelector((state) => state.branch);
  const commonState = useSelector((state) => state.common);
  // variables and functions for fetch data
  const [allUsers, setAllUsers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [isAllUsers, setIsAllUsers] = useState(false);
  const [center, setCenter] = useState({});
  const [markers, setMarkers] = useState([]);

  const staffPath = `admin/getEmployeeStaffList?userId=${user?.user_id}`;

  const getAllUser = useCallback(() => {
    const path = `admin/getEmpLocationAll?userId=${user.user_id}`;
    dispatch(commonGetDataOne(path));
  }, []);

  useEffect(() => {
    getAllUser();
  }, []);

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      if (commonState.commonGetOneData.length) {
        setIsAllUsers(true);
        // setAllUsers(commonState.commonGetOneData);
        let center = {
          lat: parseFloat(commonState.commonGetOneData[0].latitude),
          lng: parseFloat(commonState.commonGetOneData[0].longitude),
        };

        let i = 1;
        commonState.commonGetOneData.forEach(async (marker) => {
          const updatedMarker = {
            id: i,
            lat: parseFloat(marker.latitude),
            lng: parseFloat(marker.longitude),
            name: marker.staffName,
          };

          setAllUsers((prevMarkers) => [...prevMarkers, updatedMarker]);
          i++;
        });

        setCenter(center);
      }
    }
  }, [commonState.commonGetOneSuccess]);

  const fetchData = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(commonGetData(staffPath));
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (branchState.brnGetSuccess) {
      setBranches(branchState.brnGetData);
    }
    if (commonState.commonGetSuccess) {

      console.log(commonState.commonGetData)
      setStaffList(commonState.commonGetData);
      dispatch(initState());
      dispatch(initCommonState());
    }
  }, [branchState.brnGetSuccess, commonState.commonGetSuccess]);
  // Variables and functions for set map view
  const [state, setState] = useState({
    branchId: "",
    departmentId: 0,
    staffId: "",
    date: "",
  });

  const [destination, setDestination] = useState({});
  const [waypoints, setWaypoints] = useState([]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
  };

  const handleBranchSubmit = (event) => {
    setCenter({});
    setDestination({});
    const {
      target: { name, value },
    } = event;
    if (state.branchId && state.staffId && state.date) {
      const url = `admin/getEmployeeLatandLong?staffId=${state.staffId}&branchId=${value}&departmentId=0&selectedDate=${state.date}`; //${state.departmentId}

      dispatch(commonGetDataTwo(url));
    }
  };
  const handleStaffSubmit = (event) => {
    setCenter({});
    setDestination({});
    const {
      target: { name, value },
    } = event;
    if (state.branchId && state.staffId && state.date) {
      const url = `admin/getEmployeeLatandLong?staffId=${value}&branchId=${state.branchId}&departmentId=0&selectedDate=${state.date}`; //${state.departmentId}

      dispatch(commonGetDataTwo(url));
    }
  };
  const handleDateSubmit = (event) => {
    setCenter({});
    setDestination({});
    const {
      target: { name, value },
    } = event;
    if (state.branchId && state.staffId) {
      const url = `admin/getEmployeeLatandLong?staffId=${state.staffId}&branchId=${state.branchId}&departmentId=0&selectedDate=${value}`; //${state.departmentId}

      dispatch(commonGetDataTwo(url));
    }
  };

  useEffect(() => {
    if (commonState.commonGetTwoSuccess) {
      setWaypoints([]);

      setCenter({});
      setDestination({});

      if (commonState.commonGetTwoData.length < 1) {
        toast.error("Data not found with given details");
        setIsAllUsers(true);
        getAllUser();
      }
      if (
        Array.isArray(commonState.commonGetTwoData) &&
        commonState.commonGetTwoData.length > 0
      ) {

        const routeCoord = commonState.commonGetTwoData.map((row) => {
          return {
            location: {
              lat: parseFloat(row.latitude),
              lng: parseFloat(row.longitude),
            },
          };
        });

        const newWaypoint = routeCoord.filter((v, index) => {
          return index != 0 && routeCoord.length - 1 != index;
        });

        setWaypoints(newWaypoint);
        setCenter({
          ...center,
          lat: routeCoord[0].location.lat,
          lng: routeCoord[0].location.lng,
        });
        setDestination({
          ...destination,
          lat: routeCoord[routeCoord.length - 1].location.lat,
          lng: routeCoord[routeCoord.length - 1].location.lng,
        });
        setIsAllUsers(false);
      }
      dispatch(initState());
      dispatch(initCommonState());
    }
  }, [commonState.commonGetTwoSuccess]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="tracking-map-container">
        <div className="page-top-bar">Live Location </div>
        <div
          className="page-body"
          style={{ position: "relative", height: "82vh", padding: 0 }}
        >
          {isAllUsers && <MarkerMap center={center} markers={allUsers} />}
          {!isAllUsers && center != undefined && (
            <RoadMap
              center={center}
              origin={center}
              destination={destination}
              waypoints={waypoints}
            />
          )}

          <div className="center-menu">
            <Form>
              <Row>
                <Col sm={state.branchId && state.staffId ? 4 : 6}>
                  <Form.Label>Select Branch</Form.Label>
                  <Form.Select
                    name="branchId"
                    onChange={(e) => {
                      handleChange(e);
                      handleBranchSubmit(e);
                    }}
                  >
                    <option value="">Select option</option>
                    {branches?.map((v, i) => {
                      return (
                        <option value={v.branchId} key={i}>
                          {v.branch_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>

                <Col sm={state.branchId && state.staffId ? 4 : 6}>
                  <Form.Label>Select Employees</Form.Label>
                  <Form.Select
                    name="staffId"
                    onChange={(e) => {
                      handleChange(e);
                      handleStaffSubmit(e);
                    }}
                  >
                    <option value="">Select option</option>
                    {Array.isArray(staffList) &&
                      staffList.length > 0 &&
                      staffList.map((v, i) => {
                        return (
                          <option value={v.staffId} key={i}>
                            {v.full_name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
                {state.branchId && state.staffId && (
                  <Col sm="4">
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                      name="date"
                      type="date"
                      onChange={(e) => {
                        handleChange(e);
                        handleDateSubmit(e);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
