import "./Style.css";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { MdDeleteSweep } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../components/button/button";
import Spinner from 'react-bootstrap/Spinner';

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  commonSaveData,
  commonGetData,
  initState,
  initCommonState,
} from "../../redux/slices/commonSlice";

const LeaveSettings = () => {
  const params = useParams()
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const [leaveList, setLeaveList] = useState([]);
  const [leaveData, setLeaveData] = useState({
    userId: user?.user_id,
    leaveId: "",
    leaveName: "",
    leaveBalance: "",
  });

  const getLeaveList = useCallback(() => {
    dispatch(commonGetData(`admin/getModuleLeaveList?moduleId=${params.id}`));
  }, []);

  useEffect(() => {
    getLeaveList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      if (Array.isArray(state.commonGetData) && state.commonGetData.length) {
        setLeaveList(state.commonGetData);
      }
      dispatch(initState());
    }
  }, [state.commonGetSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLeaveData({
      ...leaveData,
      leaveName: "",
      leaveBalance: "",
    });
    setValidated(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({
      ...leaveData,
      [name]: value,
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const newArr = [...leaveList]
      newArr.push({ leaveId: "", leaveName: leaveData.leaveName, leaveBalance: leaveData.leaveBalance })
      const d = {
        path: "admin/insertLeaveModule",
        data: {
          moduleId: params.id,
          leaveModule: newArr
        },
      };
      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    if (state.commonSuccess) {
      handleClose();
      dispatch(initState());
      setLeaveData({
        ...leaveData,
        leaveName: "",
        leaveBalance: "",
      });
      getLeaveList();
    }
  }, [state.commonSuccess]);

  // Edit functions ==========================================================
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = (row) => {
    setIsEdit(true);
    setLeaveData({
      ...leaveData,
      leaveId: row.leaveId,
      leaveName: row.leave_name,
      leaveBalance: row.leave_balance,
    });
    handleClickOpen();
  };

  // const handeArrayChange = (e, index) => {
  //   const { target: { name, value } } = e
  //   const newArr = [...leaveList]

  //   newArr[index][name] = value
  //   console.log("newArr --- ", newArr[index][name])
  //   setLeaveList(newArr)
  // }

  const handeArrayChange = (e, index) => {
    const { name, value } = e.target;
  
    // Create a shallow copy of the array
    const newArr = [...leaveList];
  
    // Create a shallow copy of the specific object to update
    const updatedItem = { ...newArr[index], [name]: value };
  
    // Replace the item at the specified index with the updated one
    newArr[index] = updatedItem;
  
    console.log("newArr --- ", newArr[index][name]);
  
    // Update state
    setLeaveList(newArr);
  };
  

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const d = {
      path: "admin/insertLeaveModule",
      data: {
        moduleId: params.id,
        leaveModule: leaveList
      },
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions ==============================================================
  const [delId, setDelId] = useState(null);
  const handleDelete = (id) => {
    setDelId(id);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteLeave",
      data: {
        userId: user?.user_id,
        leaveId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (state.commonSuccess) {
      toast.success(state.commonMessage);
      setOpen(false);
      getLeaveList();
      setIsEdit(false);
      dispatch(initCommonState());
    }
    if (state.commonError) {
      toast.error(state.commonMessage);
      setOpen(false);
      getLeaveList();
      dispatch(initCommonState());
    }
  }, [state.commonSuccess, state.commonError]);

  const columns = [
    {
      headerName: "Leave Name",
      field: "leave_name",
      flex: 1
    },
    {
      headerName: "Leave Value",
      field: "leave_balance",
    },
    {
      headerName: "Actions",
      cellRenderer: (row) => {
        return (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row.data);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row.data)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];
  // ====================================================================================

  const handleUpdate = () => {
    const d = {
      path: "admin/insertLeaveModule",
      data: {
        moduleId: params.id,
        leaveModule: leaveList
      },
    };
    dispatch(commonSaveData(d));
  }
  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/leave-module"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Leaves</p>
      </div>

      <div className="SettingBody-card mt-4" style={{ borderRadius: "7px" }}>
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn">
            <div className="button-container">
              <Form.Group style={{ width: "200px" }}>
                <Form.Select>
                  <option value="">Select Leave Period</option>
                  <option value="1">Yearly</option>
                  <option value="2">Half Yearly</option>
                </Form.Select>
              </Form.Group>
              <ButtonCom
                type={"button"}
                class_name={"d_btn"}
                text={"Update Leave"}
                ext
                url_or_onclick={handleUpdate}
                icon={<CiEdit />}
                width={""}
              />
              <ButtonCom
                type={"button"}
                class_name={"p_btn"}
                text={"Create New Leave"}
                ext
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            {/* <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text={"Create New Leave"}
              ext
              url_or_onclick={handleClickOpen}
              icon={<AddIcon />}
              width={""}
            /> */}
          </div>
          <div className="Branches-table" style={{ height: "100vh" }}>
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: "500px", width: "100%" }}
            >
              <div className="leave-container">

                <table>
                  {leaveList.map((row, idx) => {

                    return <tr className="leaves mb-2">
                      <td width={200}>{row.leaveName}</td>
                      <td><input className="leaves-input" type="text" name="leaveBalance" value={row.leaveBalance} onChange={(e) => handeArrayChange(e, idx)} placeholder="Enter leave balance" /> Leave</td>
                      <td>{
                        row.leaveName != "Casual Leave" && row.leaveName != "Sick Leave" && <MdDeleteSweep className="icon delete" onClick={() => handleDelete(row.leaveId)} />
                      }
                      </td>
                    </tr>

                  })}
                </table>
              </div>
            </div>
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleClose(); // Only allow closing programmatically
              }
            }}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              Add New Leave
            </DialogTitle>
            <div style={{ overflow: "auto" }}>
              <Form
                noValidate
                validated={validated}
                onSubmit={isEdit ? handleEditSubmit : handleSubmit}
              >
                <DialogContent>
                  <Row>
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Leave Name <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="leaveName"
                          value={leaveData.leaveName}
                          placeholder="Enter Leave Name"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid leave name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* --------------------------------------------------- */}
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Leave Balance
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="leaveBalance"
                          value={leaveData.leaveBalance}
                          placeholder="Enter Balance"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* ----------------------------------------------------- */}
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Save &nbsp;{state.commonLoading && <Spinner animation="border" variant="light" size="sm" />}
                  </Button>
                </DialogActions>
              </Form>
            </div>
          </Dialog>
        </div>
      </div>
    </div >
  );
};

export default LeaveSettings;
