import React from "react";

const Welcome = () => {
  return (
    <div className="welcom-body">
      <div class="container">
        <h1>Welcome!</h1>
        <p>
          We’re glad to have you here. Explore our content and discover what we
          have to offer. Join us and be part of our amazing community.
        </p>
        <a href="/" class="button">
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Welcome;
