import "./style.css";
import { useEffect, useState, forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";

import ButtonCom from "../../../components/button/button";
import Switch from "@mui/material/Switch";

import { useDispatch, useSelector } from "react-redux";
import {
  commonGetDataOne,
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import { AgGridReact } from "ag-grid-react";

const EmployeeId = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];

  const [snackbar, setSnackbar] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
    Transition: Slide,
  });

  const { vertical, horizontal, openSnackbar, Transition } = snackbar;

  const handleSnackbar = (newState) => {
    setSnackbar({ ...newState, openSnackbar: true, Transition });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, openSnackbar: false });
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [employerIdNameList, setEmployeeIdList] = useState([]);

  const getEmpList = useCallback(() => {
    dispatch(
      commonGetDataOne(`admin/getEmployerIdList?userId=${user.user_id}`)
    );
  }, []);

  useEffect(() => {
    getEmpList();
  }, []);

  useEffect(() => {
    if (state.commonGetOneSuccess) {
      console.log(state.commonGetOneData);
      setEmployeeIdList(state.commonGetOneData);
    }
  }, [state.commonGetOneSuccess]);

  const [addEmployeeId, setAddEmployeeId] = useState({
    employerId: null,
    employerIdName: "",
    empIdNumber: "",
    userId: user.user_id,
  });

  const handleClose = () => {
    setAddEmployeeId({
      ...addEmployeeId,
      employerIdName: "",
    });
    setValidated(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddEmployeeId({
      ...addEmployeeId,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const body = {
        path: "admin/createEmployerId",
        data: {
          empIdNumber: addEmployeeId.empIdNumber,
          employerIdName: addEmployeeId.employerIdName,
          userId: user.user_id,
        },
      };
      dispatch(commonSaveData(body));
    }
  };

  // Edit functions
  const [show, setShow] = useState(false);
  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = (row) => {
    console.log(row)
    setIsEdit(true);
    setAddEmployeeId({
      ...addEmployeeId,
      employerIdName: row.data.employerIdName,
      employerId: row.data.employerId,
      empIdNumber: row.data.empIdFormat,
      userId: user.user_id,
    });
    handleClickOpen();
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateEmployerId",
      data: {
        employerIdName: addEmployeeId.employerIdName,
        userId: user.user_id,
        employerId:  addEmployeeId.employerId,
        empIdNumber:  addEmployeeId.empIdNumber,
      },
    };
    dispatch(commonSaveData(d));
    setOpen(false);
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    // setDelId(row.employerId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    // let d = {
    //   path: "admin/deleteRoles",
    //   data: {
    //     userId: user?.user_id,
    //     employerId: parseInt(delId),
    //   },
    // };
    // dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (state.commonSuccess) {
      toast.success(state.commonMessage);
      setOpen(false);
      getEmpList();
      setIsEdit(false);
      setDelId(null);
      setAddEmployeeId({
        ...addEmployeeId,
        employerIdName: "",
      });
      dispatch(initCommonState());
    }
    if (state.commonError) {
      toast.error(state.commonMessage);
      setOpen(false);
      getEmpList();
      setIsEdit(false);
      setDelId(null);
      setAddEmployeeId({
        ...addEmployeeId,
        employerIdName: "",
      });
      dispatch(initCommonState());
    }
  }, [state.commonSuccess, state.commonError]);

  const columns = [
    {
      headerName: "Employee Id",
      field: "employerIdName",
      flex: 1,
    },

    {
      headerName: "Actions",
      cellRenderer: (rowData) => {
        return (
          <>
            <div className="Branches-table-button">
              <CiEdit
                style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                onClick={() => {
                  handleEdit(rowData);
                }}
              />
              <MdOutlineDeleteSweep
                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                onClick={() => handleDelete(rowData)}
              />
            </div>
          </>
        );
      },
    },
  ];
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete Employer ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Employee ID</p>
      </div>

      <div className="SettingBody-card mt-2">
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn">
            {/* 
            this is on holding for now and will be appear later
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "5px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "5px",
                marginRight: "10px",
                padding: "0 10px"
              }}
            >
              <span>Existing Employee Id</span>
              <Switch
                checked={checked}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
                color="primary"
              />
            </div> */}

            <ButtonCom
              type="button"
              class_name="p_btn"
              text="Add New Employee Id"
              url_or_onclick={handleClickOpen}
              icon={<AddIcon />}
              width={""}
            />
          </div>
          <div className="Branches-table">
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: "380px", width: "100%" }}
            >
              <AgGridReact
                rowData={
                  employerIdNameList.length > 0 ? employerIdNameList : []
                }
                columnDefs={columns}
                omLayout="autoHeight"
                pagination={true}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <h2> {isEdit ? "Update " : "Create "}  Employee id</h2>
        </DialogTitle>
        <Form
          noValidate
          validated={validated}
          onSubmit={isEdit ? handleEditSubmit : handleSubmit}
        >
          <DialogContent>
            <Row>
              <Col sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="employerIdName"
                    value={addEmployeeId.employerIdName}
                    placeholder="Enter Employee Id"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid employee id.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>Employer ID Number</Form.Label>
                  <Form.Select
                    type="text"
                    name="empIdNumber"
                    value={addEmployeeId.empIdNumber}
                    onChange={handleChange}
                    required
                  >
                    <option value={""}>Select Employer Id Number</option>
                    <option value={"1"}>1</option>
                    <option value={"2"}>01</option>
                    <option value={"3"}>001</option>
                    <option value={"4"}>0001</option>
                  </Form.Select>
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid employer id number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="gray"
              onClick={handleClose}
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
                backgroundColor: "gray",
                color: "white",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              type="submit"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message={message}
        key={Transition.name + vertical + horizontal}
        autoHideDuration={3000}
        color="success"
        variant="outlined"
        TransitionComponent={state.Transition}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmployeeId;
