import "./Holiday.css";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";

import { useDispatch, useSelector } from "react-redux";
import { saveHoliday, } from "../../../redux/slices/holidaySlice";
import {
  commonSaveData,
  commonGetData,
  initCommonState,
} from "../../../redux/slices/commonSlice";

const Holiday = () => {
  const params = useParams()
  const moduleId = params.id
  const dispatch = useDispatch();
  const holidayState = useSelector((state) => state.holiday);
  const commonState = useSelector((state) => state.common);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [holiday, setHoliday] = useState({
    userId: user.user_id,
    moduleId: moduleId,
    holiday_name: "",
    holiday_date: ""
  });

  const fetchHolidayList = useCallback(() => {
    const path = `admin/getMyHolidayList?holidayId=${params.id}`
    dispatch(commonGetData(path));
  }, []);

  useEffect(() => {
    fetchHolidayList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      if(Array.isArray(commonState.commonGetData) && commonState.commonGetData.length){
        setHolidayList(commonState.commonGetData);
      }
    }
  }, [commonState.commonGetSuccess, commonState.commonGetError]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHoliday({
      ...holiday,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const data = {
        path: `admin/createMyHoliday`,
        data: holiday
      }
      dispatch(commonSaveData(data));
    }
  };

 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setHoliday({
      ...holiday,
      holiday_name: "",
      holiday_date: "",
    });
    setValidated(false);
    setOpen(false);
  };

  // Edit functions
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = (row) => {
    setIsEdit(true);
    setHoliday({
      ...holiday,
      holidayId: row.data.holidayId,
      holiday_name: row.data.holidayName,
      holiday_date: row.data.holidayDate,
    });
    handleClickOpen();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateMyHoliday",
      data: holiday,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.data.holidayId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteMyHoliday",
      data: {
        holidayId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      setIsEdit(false);
      dispatch(initCommonState());
      setHoliday({
        ...holiday,
        holiday_name: "",
        holiday_date: ""
      })
      setTimeout(()=>{
        fetchHolidayList();
      }, 500)
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      fetchHolidayList();
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const columns = [
    {
      headerName: "Holiday Name",
      field: "holidayName",
      flex: 1,
    },
    {
      headerName: "Date",
      field: "holidayDate",
    },
    {
      headerName: "Actions",
      cellRenderer: (row) => {
        return (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
      // width: "200px", 
    },
  ];

  //===============================
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  
  // Rendering HTML Elements ===========================================================
  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete Break</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="SettingBody-head">
        <Link to={"/holiday-module/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Holiday</p>
      </div>

      <div className="SettingBody-card mt-3">
        <div className="Branches-body" style={{ borderRadius: "7px", width: "100%" }}>
          <div className="Branches-table" style={{ width: "100%" }}>
            <div className="Holiday-table-head">
              <div
                className="Holiday-table-title"
                style={{ backgroundColor: "red" }}
              >
                {/* <h2>Holiday List</h2> */}
                <input
                  type="month"
                  style={{ padding: "8px 10px", width: "100%" }}
                />
              </div>
              <div className="Holiday-table-btn mb-3">
                {/* <button id="Add-Holiday-btn">Add All Holiday</button> */}
                <ButtonCom
                  type={"button"}
                  class_name={"p_btn"}
                  text={"Add New Holidays"}
                  ext
                  url_or_onclick={handleClickOpen}
                  icon={<AddIcon />}
                  width={""}
                />
              </div>
            </div>
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: 350, width: "100%" }}
            >
              {
                <AgGridReact
                  rowData={holidayList}
                  columnDefs={columns}
                  omLayout="autoHeight"
                  pagination={true}
                  paginationPageSize={paginationPageSize}
                  paginationPageSizeSelector={paginationPageSizeSelector}
                /> 
              }
            </div>

          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        // onClose={handleClose}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(); // Only allow closing programmatically
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <h3>Add New Holiday</h3>
        </DialogTitle>
        <Form
          noValidate
          validated={validated}
          onSubmit={isEdit ? handleEditSubmit : handleSubmit}
        >
          <DialogContent>
            <Row>
              <Col sm="12">
                <Form.Group className="mb-2">
                  <Form.Label>Holiday Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="holiday_name"
                    value={holiday.holiday_name}
                    placeholder="Enter Holiday Name"
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid holiday name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* ---------------------------- */}
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Holiday Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="holiday_date"
                    // value="2024-12-02"
                    defaultValue={holiday.holiday_date}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type={"invalid"}>
                    Please provide valid holiday date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="gray"
              onClick={handleClose}
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
                backgroundColor: "gray",
                color: "white",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              type="submit"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "30px",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default Holiday;
