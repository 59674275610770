import React, { useCallback, useEffect } from "react";
import "./AdminNavBare.css";
import logo from "../../../assets/Images/Logo.png";
import { NavLink, Link } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";
import TimerIcon from "@mui/icons-material/Timer";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RoomIcon from "@mui/icons-material/Room";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import { FaRegBell } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  commonGetData,
  commonNotification,
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const AdminNavBare = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];
  const navigate = useNavigate();
  const [isOpenSetting, setIsOpenSetting] = useState(false);

  const dispatch = useDispatch();
  const commonStore = useSelector((state) => state.common);

  const [notifications, setNotification] = useState(0);
  const getNotification = useCallback(() => {
    const path = `admin/getNotifications?userId=${user.user_id}`;
    dispatch(commonNotification(path));
  }, []);
  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (commonStore.commonNotificationSuccess) {
      console.log(commonStore.commonNotificationData)
        setNotification(commonStore.commonNotificationData.pendingApprovalsCount);
    }
    if (commonStore.commonNotificationError) {
      console.log(commonStore.commonNotificationMessage)
    }
  }, [commonStore.commonNotificationSuccess, commonStore.commonNotificationError]);

  //

  const slider = [
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   icon: <SpaceDashboardIcon />,
    // },
    {
      path: "/empmanagement",
      name: "Employee ",
      icon: <PersonIcon />,
    },
    {
      path: "/live-attendance",
      name: "Attendance ",
      icon: <TimerIcon />,
      isDropdown: true,
      chi: [
        { path: "live-attendance", name: "Live Attendance" },
        { path: "daily-attendance", name: "Daily Attendance" },
      ],
    },
    { path: "/payroll", name: "Payroll", icon: <ExitToAppIcon /> },
    { path: "/tracking", name: "Tracking", icon: <RoomIcon /> },
    {
      path: "/performance-management",
      name: "Performance Management",
      icon: <MonitorHeartIcon />,
    },

    {
      path: "/raining-list",
      name: "Training & Development",
      icon: <CastForEducationIcon />,
    },
    {
      path: "/reports",
      name: "Reports",
      icon: <BarChartIcon />,
    },
    // {
    //   path: "/setting",
    //   name: "Setting",
    //   icon: <SettingsIcon onClick={() => setIsOpen(!isOpen)} />,
    // },
    {
      path: "/notification",
      name: "notification",
      icon: (
        <div className="notification-container">
          <div className="notification-icon">
            <FaRegBell style={{ fontSize: "20px" }} />
            <span className="notification-count">{notifications}</span>
          </div>
        </div>
      ),
    },
  ];

  const navigateDashboard = () => {
    document.getElementById("dash").click();
  };

  const actions = [
    { icon: <DashboardIcon />, name: "Dashboard", path: "/dashboard" },
    { icon: <SettingsIcon />, name: "Setting", path: "/setting" },
  ];

  const [path, setPath] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePath = (path) => {
    setPath(path);
    handleClose();
    setTimeout(() => {
      document.getElementById("custom-path").click();
    }, 100);
  };

  return (
    <>
      {/* <div className="chat-popup-container">
        
        <button
          className="chat-button"
          onClick={() => setIsOpenSetting((prev) => !prev)}
        >
          {isOpenSetting ? "✖" : <CiSettings />}
        </button>

      
        {isOpenSetting && (
          <div className="chat-popup">
            <div className="chat-header">
              <h4>Settings</h4>
            </div>
            <div className="chat-body">
              <ul>
                <li>
                  <Link
                    to="/dashboard"
                    onClick={() => setIsOpenSetting((prev) => !prev)}
                  >
                    <SpaceDashboardIcon /> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/setting"
                    onClick={() => setIsOpenSetting((prev) => !prev)}
                  >
                    <SettingsIcon /> Setting
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div> */}
      <Link to={path} id="custom-path" style={{ display: "none" }}></Link>
      <Box
        sx={{
          height: 320,
          transform: "translateZ(0px)",
          flexGrow: 1,
          "& .MuiFab-primary": {
            backgroundColor: "#353636", // Change the background color
            "&:hover": {
              backgroundColor: "#262627", // Change the hover background color
            },
          },
        }}
        style={{
          position: "fixed",
          bottom: "0",
          right: "10px",
          zIndex: "1000",
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial controlled open example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                setOpen(false);
                navigate(action.path);
              }}
            />
          ))}
        </SpeedDial>
      </Box>

      <Link to="/dashboard" id="dash" style={{ display: "none" }}></Link>
      <nav className="AdminNavBare" style={{ zIndex: 2 }}>
        <div className="AdminNavBare-left">
          <img
            src={logo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={navigateDashboard}
          />
        </div>

        <div className="AdminNavBare-Right">
          <ul>
            {slider.map((item, i) => (
              <NavLink
                to={item.path}
                className="nav-bar"
                style={{ textDecoration: "none" }}
                key={i}
              >
                {({ isActive }) => (
                  <>
                    <label
                      className="nav_link"
                      key={i}
                      style={{
                        backgroundColor:
                          isActive && item.name != "notification" ? "#fff" : "",
                        color:
                          isActive && item.name != "notification"
                            ? "black"
                            : "",
                      }}
                    >
                      {item.icon}
                      {item.name != "notification" ? item.name : null}
                    </label>

                    {item.isDropdown && (
                      <ul className="SubMenu" id={"dp" + i}>
                        {item.chi.map((r, id) => {
                          return (
                            <li key={id}>
                              <NavLink to={r.path}> {r.name}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </>
                )}
              </NavLink>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default AdminNavBare;
