import { Col, Form, Row } from "react-bootstrap";
import "./Notification.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  commonGetData,
  commonSaveData,
  initCommonState,
} from "../../redux/slices/commonSlice";

const Notification = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];

  const dispatch = useDispatch();
  const commonStore = useSelector((state) => state.common);

  const [description, setDescription] = useState([]);

  const getNotification = useCallback(() => {
    const path = `admin/getNotifications?userId=${user.user_id}`;
    dispatch(commonGetData(path));
  }, []);
  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (commonStore.commonGetSuccess) {
      setDescription(commonStore.commonGetData.leaveRequests);
    }
    if (commonStore.commonGetError) {
    }
  }, [commonStore.commonGetSuccess, commonStore.commonGetError]);

  const submitNotification = (id, staffId, status) => {
    const data = {
      path: "admin/notifyApproveRequest",
      data: {
        notifyId: id,
        staffId: staffId,
        approve: status,
      },
    };
    dispatch(commonSaveData(data));
  };

  useEffect(() => {
    if (commonStore.commonSuccess) {
      toast.success(commonStore.commonMessage);
      dispatch(initCommonState);
      getNotification();
    }
    if (commonStore.commonError) {
      toast.error(commonStore.commonMessage);
      dispatch(initCommonState);
    }
  }, [commonStore.commonSuccess, commonStore.commonError]);
  // return html elements
  // notification page
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="SettingBody">
        <div className="SettingBody-head">
          {/* <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} />
          </Link> */}
          <p>Notification</p>
        </div>

        <div className="SettingBody-card mt-3">
          {/* Button area */}
          <div className="notification_header">
            <Form>
              <Row>
                <Col sm="3">
                  <Form.Group>
                    <Form.Control placeholder="Search staff by name" />
                  </Form.Group>
                </Col>

                <Col sm="3">
                  <Form.Group>
                    <Form.Select>
                      <option value={""}>Branch</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                {/* <Col sm="3">
                  <Form.Group>
                    <Form.Select>
                      <option value={""}>Department</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col sm="3">
                  <Form.Group>
                    <Form.Control type="date" />
                  </Form.Group>
                </Col> */}
              </Row>
            </Form>
          </div>

          <div>
            {/* Button area */}

            <div
              className="Branches-body"
              style={{ borderRadius: "7px", marginTop: "90px" }}
            >
              <Row>
                {description?.map((row, idx) => {
                  return (
                    <Col sm="6" key={idx} className="mb-3">
                      <div className="card-container">
                        {row.approved != 0 && (
                          <span
                            className={
                              row.approved == "1"
                                ? "approved status"
                                : row.approved == "2"
                                ? "rejected  status"
                                : "canceled status"
                            }
                          >
                            {row.approved == "1"
                              ? "Approved"
                              : row.approved == "2"
                              ? "Rejected"
                              : "Canceled"}
                          </span>
                        )}
                        {/* referenceId */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "15px"
                          }}
                        >
                          <h4 className="card-title">
                            Employee Name: <span>{row.staffName}</span>
                          </h4>
                          <h4 className="card-title">
                            Employee Id: <span>{row.empId}</span>
                          </h4>
                        </div>

                        <h4 className="card-title">
                          Leave Type: <span>{row.leave_name}</span>
                        </h4>
                        <p className="card-description">
                          <b>Reason: </b>
                          {row.reason}
                        </p>
                        <p className="card-description">
                          <b>Applied Date:</b>  { row.referenceId == 1 ? row.start_date + " - " +row.end_date : row.createdDate}
                        </p>
                        {row.approved == 0 && (
                          <div className="card-actions">
                            <button
                              className="approve-btn"
                              onClick={() =>
                                submitNotification(row.id, row.staff_id, 1)
                              }
                            >
                              Approve
                            </button>
                            <button
                              className="reject-btn"
                              onClick={() =>
                                submitNotification(row.id, row.staff_id, 2)
                              }
                            >
                              Reject
                            </button>
                            <button
                              className="cancel-btn"
                              onClick={() =>
                                submitNotification(row.id, row.staff_id, 3)
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Notification);
